export const getAppConfigs = () => window.appConfigs || {};
//console.log("config", window.appConfigs)
export const isExternalUser = () => window.appConfigs && window.appConfigs.isExternalUser;
export const showPowerBI = () => window.appConfigs && window.appConfigs.showPowerBI;
export const showProfile = () => window.appConfigs && window.appConfigs.showProfile;
export const showTakeTour = () => window.appConfigs && window.appConfigs.showTakeTour;
export const showUpload = () => window.appConfigs && window.appConfigs.showUpload;
export const showAMR = () => window.appConfigs && window.appConfigs.showAMR;
export const showHome = () => window.appConfigs && window.appConfigs.showHome;
export const showCG = () => window.appConfigs && window.appConfigs.showCG;
export const showNotifications = () => window.appConfigs && window.appConfigs.showNotifications;
export const showCao = () => window.appConfigs && window.appConfigs.showCao;
export const showDataLabel = () => window.appConfigs && window.appConfigs.showDataLabel;
export const showExtPre = () => window.appConfigs && window.appConfigs.showExtPre;
export const supportedLanguages = () => window.appConfigs && window.appConfigs.supportedLanguages;
export const supportedTotalsizePerUpload = () => getItemFromLS('cObj','supported_total_size_per_upload');
export const supportedEachDocSize = () => getItemFromLS('cObj','supported_each_doc_size');
export const supportedUploadDocCount = () => getItemFromLS('cObj','supported_total_count_per_upload');

export const uploadSizeErrorReg = () => window.appConfigs && window.appConfigs.uploadSizeErrorReg;
export const uploadCountError = () => window.appConfigs && window.appConfigs.uploadCountError;

export const translationDisclaimer = () => window.appConfigs && window.appConfigs.translationDisclaimer;


export const getMaxNumInArray = arr => arr && arr.length > 0 ? Math.max(...arr) : 0;

export const getItemFromLS = (name, key) => {
    const valObj = localStorage.getItem(name);
    if (valObj) {
        const parsedObj = JSON.parse(valObj);
        if (parsedObj && key) {
            return parsedObj[key];
        }
    }
    return null;
}

export const getSentimentFilter = selectedSentiments => {
    if (selectedSentiments.length === 0 || selectedSentiments.length === 2) return 'POS,NEG';
    return selectedSentiments[0];

}

export const getErrorMessages = (type, subType) => {
    let msg = '';
    if (window.appConfigs) {
        if (type === '500') {
            msg = window.appConfigs.genericError500;
        }

        //  for upload api
        if (type === 'U') { // upload
            if (subType === 'V_1') {
                msg = window.appConfigs.genericErrorUpload1;
            } else if (subType === 'V_2') {
                msg = window.appConfigs.genericErrorUpload2;
            }          
        }

        // for scan api
        if (type === 'S') { //scan
            msg = window.appConfigs.genericErrorScan;
        }

        if (type === 'IP') {// msg to show analysis is still in progress before loading pbi report
            msg = window.appConfigs.genericErrorInProgress;
        } 

        if (type === 'ZD') {// msg to show zero documents project before loading pbi report
            msg = window.appConfigs.genericErrorZeroDoc;
        }
    }
    return msg;
}


export const getSentimentScale = (sentimentData, selectedSentiment) => getMaxNumInArray(sentimentData.map(ps => {
    if (selectedSentiment === 'POS') return ps.positiveSentimentScore;
    else if (selectedSentiment === 'NEG') return ps.negativeSentimentScore;
    return ps.negativeSentimentScore + ps.positiveSentimentScore;
}));

export const getSentimentScaleForSS = (sentimentData, selectedSentiment) => getMaxNumInArray(sentimentData.map(ps => {
    if (selectedSentiment === 'POS') return ps.pos;
    else if (selectedSentiment === 'NEG') return ps.neg;
    return ps.neg + ps.pos;
}));

export const stringifyArrayForOdataQuery = arr => JSON.stringify(arr).replace(/\[/, '(').replace(/\]/, ')');

export const removeDuplicatesFromArrayByKey = (arr, key) => {
    const keyMap = {};

    arr.forEach(obj => {
        keyMap[obj[key]] = obj;
    });

    return Object.values(keyMap);
}

export const stripHTMLTagsFromString = str => str.replace(/(<([^>]+)>)/ig, '');

export const stripSpacesFromString = str => str.replace(/\s/g, '');

export const transformFilterLabels = labels => {
    if (!labels || labels.length === 0) return 'All';
    else if (labels.length <= 1) return labels.join()

    return `${labels[0]} & ${labels.length - 1}+`;
};

//export const formatNumber = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
export const formatNumber = num => num.toLocaleString();

export const transformArrayValuesToString = arr => arr ? arr.map(obj => obj.value) : [];

export const sortDataBySentimentScoreInAsc = (arr, selectedSentiment) => {
    let sortFn = null;
    if (selectedSentiment === 'POS') sortFn = (a, b) => b.positiveSentimentScore > a.positiveSentimentScore ? 1 : -1;
    else if (selectedSentiment === 'NEG') sortFn = (a, b) => b.negativeSentimentScore > a.negativeSentimentScore ? 1 : -1;
    else sortFn = (a, b) => b.negativeSentimentScore + b.positiveSentimentScore > a.negativeSentimentScore + a.positiveSentimentScore ? 1 : -1;

    return arr.sort(sortFn);
};

export const sortDropdownOptions = options => options.sort((a, b) => b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 1);

export const getFilteredSearchResults = (searchResultData) => {
    let companyData = [];
    searchResultData.forEach((i) =>
        companyData.push({ compName: i.document.name, text: i.text, id: i.document.id })
    );
    return companyData;
};
export const getFilteredSearchResultsForAll = (searchResultData) => {
    let companyData = [];
    searchResultData.forEach((i) =>
        companyData.push({category:i.category, name: i.name, text: i.text,isDisplay:i.isDisplay })
    );
    // console.log("Comp:",companyData);
    return companyData;
};

// FILE SIZE formatter
export const formatBytes = (bytes, decimals = 2) => {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let finalSize; 

    if (bytes === null || bytes === undefined){
        finalSize = '';
    }else if(bytes === 0){
        finalSize = '0 Byte';
    }else{
        const i = Math.floor(Math.log(bytes) / Math.log(k));    
        const result = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        finalSize = Math.round(result)+ ' ' + sizes[i];   
    } 
    return finalSize; 
}

// singular/plural appender with 's' based on 1 or more count
export const checkPluralText = (count) => {
    let returnFlag = false;
    if (!isNaN(parseInt(count))) {
        if (parseInt(count) > 1) {
            returnFlag = true;
        }
    }
    return returnFlag;
}


//verify input values for security chars checks
export const checkInvalidCharacters = (input) => {
    let pattern = /[<{^>}]/;
    return pattern.test(input);
}