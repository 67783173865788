export const dataSecurityFaq = [
    {
       ques: 'Where is MALENA user data stored and hosted?',
       isOpen: false
    },
    {
        ques: 'What user data can MALENA access?',
        isOpen: false
    },
    {
        ques: 'What user data is stored and for how long?',
        isOpen: false
    },
    {
        ques: 'What data does MALENA provide?',
        isOpen: false
    },
    {
        ques: 'How is user data managed by IFC/WBG?',
        isOpen: false
    },
    {
        ques: 'How is user data separated from the data of other users?',
        isOpen: false
    },
    {
        ques: 'Does MALENA back up data?',
        isOpen: false
    },
    {
        ques: 'Are there any service guarantees or verification of data input and output?',
        isOpen: false
    },
    {
        ques: 'Does MALENA have the capability to recover data in the case of a failure or data loss?',
        isOpen: false
    },
    {
        ques: 'What is MALENA’s approach to cybersecurity?',
        isOpen: false
    },
    {
        ques: 'Does MALENA have a Business Continuity Plan (BCP) and Disaster Recovery Plan (DRP)? ',
        isOpen: false
    },
    {
        ques: 'Is user data currently exposed by IFC to, or processed by, any generative AI tools?',
        isOpen: false
    },
    {
        ques: 'Does IFC use subcontractors or third-party service providers for software development in relation to MALENA?',
        isOpen: false
    },
    {
        ques: 'Are these FAQs part of the contract that I (a user) enter into with IFC, to use MALENA, or part of any contract with IFC or WBG?',
        isOpen: false
    },
    {
        ques: 'Who can I contact if I have concerns about information security?',
        isOpen: false
    },
];