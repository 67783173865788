import React, { useState, useEffect, } from "react";

import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, RadialLinearScale,
    Title, Tooltip, Legend, ArcElement, DoughnutController
} from 'chart.js';

// import './DocAnalysisCharts.scss';
import { mockSentenceAiData } from "../../common/DocAnalysisCharts/docAnalysisData";


const ProgressCharts = () => {

    ChartJS.register(LineController, LineElement, PointElement, LinearScale, RadialLinearScale,
        Tooltip, Legend, Title, ArcElement, DoughnutController);


    const [progressChartsData, setProgressChartsData] = useState([]);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {

        let defaultModelType = 'transformerbasedmodel';

        generateChartsData(mockSentenceAiData[defaultModelType]);

    }, []); // one time useeffect - on load


    const generateChartsData = (sentenceAiData) => {
        let allModelData = {};

        allModelData = sentenceAiData;

        // const finalRadarData = buildRadarData(allModelData);
        const finalProgressChartsData = buildProgressCharts(allModelData);

        setProgressChartsData(finalProgressChartsData);

    }

    const buildProgressCharts = (sentenceAiData) => {
        let finalData = [];
        if (sentenceAiData && sentenceAiData.aboutProgressChartData) {
            const apiData = sentenceAiData.aboutProgressChartData;
            apiData.forEach((item, ind) => {
                if (item.benchMarkScore && item.label === 'Drift') {
                    let arr = [];
                    let colors = [];
                    let diff = 0;
                    const redWidth = 1;
                    if (item.benchMarkScore > item.selectedScore) {
                        diff = item.benchMarkScore - item.selectedScore;
                        arr = [item.selectedScore, diff, redWidth, (100 - (item.selectedScore + diff + redWidth))];
                        colors = ['rgb(54, 162, 235)', '#cbcbcb', '#EA6D57', '#cbcbcb'];
                    } else if (item.benchMarkScore < item.selectedScore) {
                        diff = item.selectedScore - item.benchMarkScore;
                        if (diff <= redWidth) {
                            arr = [item.benchMarkScore, redWidth, (100 - (item.benchMarkScore + redWidth))];
                            colors = ['rgb(54, 162, 235)', '#EA6D57', '#cbcbcb'];
                        } else {
                            arr = [item.benchMarkScore, redWidth, item.selectedScore - (item.benchMarkScore + redWidth), (100 - item.selectedScore)];
                            colors = ['rgb(54, 162, 235)', '#EA6D57', 'rgb(54, 162, 235)', '#cbcbcb'];
                        }
                    } else {
                        arr = [item.selectedScore, redWidth, (100 - (item.selectedScore + redWidth))];
                        colors = ['rgb(54, 162, 235)', '#EA6D57', '#cbcbcb'];
                    }
                    let doughData = {
                        labels: [item.label, 'Threshold', item.label, 'Remaining'],
                        datasets: [{
                            label: item.label,
                            data: arr,
                            backgroundColor: colors,
                            hoverOffset: 0,
                            borderWidth: 0,
                            ownSelectedVal: item.selectedScore,
                            ownBenchmarkVal: item.benchMarkScore ? item.benchMarkScore : null
                        }]
                    };
                    let obj = {
                        title: item.label,
                        id: item.id,
                        description: item.description,
                        doughnutData: doughData
                    };
                    finalData.push(obj);

                } else {
                    let doughData = {
                        labels: [item.label, 'Remaining'],
                        datasets: [{
                            label: item.label,
                            data: [item.selectedScore.toFixed(2), 100 - item.selectedScore.toFixed(2)],
                            backgroundColor: ['rgb(54, 162, 235)', '#cbcbcb'],
                            hoverOffset: 0,
                            borderWidth: 0
                        }]
                    };
                    let obj = {
                        title: item.label,
                        id: item.id,
                        description: item.description,
                        doughnutData: doughData
                    };
                    finalData.push(obj);
                }

            });
        }
        return finalData;
    }

    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
        radius: '68%',
        cutout: '86%',
         // responsive: false
    }

    const doughnutPlugins = [{
        beforeDraw: function (chart) {
            // console.log(chart.data.datasets[0]);
            if (chart.data && chart.data.datasets[0]) {

                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();

                // logic for bold value
                var fontSize1 = (height / 114).toFixed(2);
                ctx.font = "bolder " + fontSize1 + "em Effra";
                ctx.textBaseline = "top";
                ctx.fillStyle = '#000000';

                var text1 = chart.data.datasets[0].ownSelectedVal ? chart.data.datasets[0].ownSelectedVal : chart.data.datasets[0].data[0],
                    textX1 = Math.round((width - (ctx.measureText(text1).width)) / 2),
                    textY1 = (height / 2 - ((ctx.measureText(text1).fontBoundingBoxDescent) * 0.7));
                ctx.fillText(text1, textX1, textY1);
                ctx.restore();

                //logic for '/ 100'
                var fontSize2 = fontSize1 * 0.4;
                ctx.font = fontSize2 + "em Effra";
                ctx.fillStyle = '#000000';
                ctx.textBaseline = "top";
                var text2 = "out of 100",
                    textX2 = Math.round((textX1 + ctx.measureText(text1).width) / 1.25),
                    textY2 = (height / 2 + ((ctx.measureText(text1).fontBoundingBoxDescent) * 1.05));
                ctx.fillText(text2, textX2, textY2);
                ctx.restore();

                //logic for 'score'
                /*var fontSize3 = (fontSize1 * 0.74);
                ctx.font = fontSize3 + "em Effra";
                ctx.textBaseline = "top";
                var text3 = "Score",
                    width4 = Math.round((ctx.measureText(text1 + text2).width)/2),
                    textX3  = Math.round ((textX1 + textX2) / 1.8),
                    textY3 = Math.round ((height / 2) - ((ctx.measureText(text2).fontBoundingBoxDescent) * 1.2));
                ctx.fillText(text3, textX3, textY3);
                ctx.restore();*/

                //for drift
                // if (chart.data.datasets[0].label === 'Drift') {
                if (chart.data.datasets[0].label === 'Drift' && chart.data.datasets[0].ownBenchmarkVal && chart._metasets[0]) {
                    let benchMarkScore = chart.data.datasets[0].ownBenchmarkVal;
                    let meta = chart._metasets[0];
                    let colors = meta._dataset.backgroundColor;
                    let thresholdIndex = 2;
                    colors.forEach((ele, ind) => {
                        if (ele === '#EA6D57') {
                            thresholdIndex = ind;
                        }
                    });
                    let data = meta.data[thresholdIndex];

                    let startAngle = data.startAngle;
                    let endAngle = data.endAngle;
                    let middleAngle = startAngle + ((endAngle - startAngle) / 2);

                    let mid_radius = data.innerRadius - 12;
                    if (benchMarkScore >= 10 && benchMarkScore <= 60) {
                        mid_radius = data.innerRadius - 30;
                    }

                    // Compute text location
                    // let posX = (radius/2) * Math.cos(middleAngle) + midX;
                    // let posY = (radius/2) * Math.sin(middleAngle) + midY;
                    var fontSize4 = "bolder " + fontSize1 * 0.6;
                    ctx.font = fontSize4 + "em Effra";
                    ctx.fillStyle = '#EA6D57';
                    let tryposx1 = mid_radius * Math.cos(middleAngle) + data.x;
                    let tryposy1 = mid_radius * Math.sin(middleAngle) + data.y;

                    // console.log(tryposx1);
                    ctx.fillText(benchMarkScore, tryposx1, tryposy1);
                    ctx.restore();
                }

                ctx.save();
            }
        }
    }];


    return (
        <div className="doc-analysis-main row" style={{ width: '100%', height: '100%' }}>
            {progressChartsData && progressChartsData.map((chart, ind) => (
                <div className="progress-chart-col col-lg-4 col-md-4 col-sm-6" key={ind}>
                    
                        <div className={`card-content ${chart.id}`}>
                            <div className="chart-title"><span>{chart.title}</span></div>
                            <div className="chart-wrapper">
                                <Chart className="progress-chart-main" type="doughnut" data={chart.doughnutData} options={doughnutOptions} plugins={doughnutPlugins}></Chart>
                            </div>
                        </div>

                    </div>
                
            ))
            }
        </div>
    )
}

export default withRouter(ProgressCharts);