import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import PublicFooter from "./PublicFooter";
import PublicHeader from "./PublicHeader";

import './publicHomePage.scss';
import { Form, FormGroup, Label, Button, Modal, ModalBody } from "reactstrap";
import { validateEmailPattern, validateSecurityTextPattern } from "./utils/publicUtils";
import { sendContatUsApi } from "./publicService";
import { getErrorMessages } from "../../common/Utils";
import PublicDataModal from "./utils/PublicDataModal";


const PublicContactUs = ({loginClick}) => {  
    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [subjectErr, setSubjectErr] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [captchaErr, setCaptchaErr] = useState('');
    const [apiErr, setApiErr] = useState(null);
    const [requestId, setRequestId] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(()=>{
        // start of adobe analytics logic
        document.getElementById('adb-analytics') != null && document.getElementById('adb-analytics').remove();
        const script = document.createElement("script");
        script.src = "./js/AnalyticsContactus.js";
        script.id = "adb-analytics";
        script.async = true;
        document.body.appendChild(script);
        // end of adobe analytics logic

        if (window.location && window.location.pathname) {
            window.scrollTo(0, 0);
        }
        loadCaptchaEnginge(4);
    },[])

    const isValidValue = (input) => {
        return input && (input !== undefined || input !== null) && input.trim() !== '';
    }

    const loadCaptchaAgain = () => {
        loadCaptchaEnginge(4);
    };

    const handleReset= ()=>{
        setUserEmail('');
        setSubject('');
        setMessage('');
        setCaptcha('');
        setEmailErr('');
        setSubjectErr('');
        setMessageErr('');
        setCaptchaErr('');
        loadCaptchaEnginge(4);
    }

    const handleSubmit= ()=>{
        if(!isValidValue(userEmail)){
            setEmailErr('This field is mandatory');
        }else if(validateEmailPattern(userEmail)){
            setEmailErr('Invalid Email');
        }else{
            setEmailErr('');
        }
        if(!isValidValue(subject)){
            setSubjectErr('This field is mandatory');
        }else{
            setSubjectErr('');
        }
        if(!isValidValue(message)){
            setMessageErr('This field is mandatory');
        }else{
            setMessageErr('');
        }
        if(validateCaptcha(captcha) == true){
            setCaptchaErr(''); 
            if(isValidValue(userEmail) && isValidValue(subject) && isValidValue(message) && !validateEmailPattern(userEmail)){
                sendContactInfo();
                handleReset();
            }else{
                setCaptcha('');
                loadCaptchaEnginge(4);
            }
        }else{
            setCaptchaErr('Invalid Captcha');            
        }
        
    }

    const sendContactInfo = ()=>{
        const contactPayload ={
            "email": userEmail.trim(),
            "subject": subject.trim(),
            "message": message.trim()
        }
        sendContatUsApi(contactPayload).then(res => {
            if (res) {
                let parsedRes =  JSON.parse(res);
                if(parsedRes.id){
                    // show a popup
                    setShowModal(true);
                    setRequestId(parsedRes.id);
                }                
            }else{
                setApiErr(getErrorMessages('500'));
            }
        }).catch(err => {
            // show 500 error message
            setApiErr(getErrorMessages('500'));
        });
    }
    const receiveModalInputs = () => {
        setShowModal(false);
    }
    return (     
        <>   
            <div className="contact-us-main">
                <PublicHeader applyWhite={true} loginC={loginClick}></PublicHeader>
                <div className="contact-us-body">
                    <div className="contact-intro-box">
                        <div className="intro-wrapper">
                            <h2>Contact Us</h2>
                            <p className="intro-text">We’d love to hear from you. Whether you have suggestions on how to improve the platform, questions, or concerns – let us know by submitting the below form. We will be in touch soon.</p>
                        </div>
                    </div>
                    <div className="contact-form-box">                
                        <div className="form-wrapper">
                            <p className="form-note">All Fields are mandatory</p>
                            <Form tag={`public-contactUs-form`}>                              
                                <div className="row main-form-row">
                                    <div className="col-sm-12">
                                        <FormGroup>
                                            <div className="form-label">
                                                <Label data-for="user_email"><span>{`Email`}</span></Label>
                                            </div>
                                            <div className="form-input">
                                                <input id={`user_email`} name="user-email" value={userEmail} maxLength={100}
                                                    placeholder="Enter Email" className={emailErr?'err-input':''} type={'text'}
                                                    onChange={(e)=>{setUserEmail(e.target.value)}} autoComplete="off" />                                        
                                            </div>                                                                                     
                                            {validateSecurityTextPattern(userEmail) ? <em className="color-red">{`special charatcters {<\\^/>} are invalid`}</em> : ''}
                                            {emailErr?<em className="color-red">{emailErr}</em>:''}
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="form-label">
                                                <Label data-for="subject"><span>{`Subject`}</span></Label>
                                            </div>
                                            <div className="form-input">
                                                <input id={`subject`} name="subject" value={subject} maxLength={100} 
                                                    placeholder="Enter Subject" className={subjectErr?'err-input':''}
                                                    onChange={(e)=>{setSubject(e.target.value)}} autoComplete="off" type={'text'} />
                                            </div>
                                            {validateSecurityTextPattern(subject) ? <em className="color-red">{`special charatcters {<\\^/>} are invalid`}</em> : ''}
                                            {subjectErr?<em className="color-red">{subjectErr}</em>:''}
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="form-label">
                                                <Label data-for="message"><span>{`Message`}</span></Label>
                                            </div>
                                            <div className="form-input">
                                                <textarea id={`message`} name="message" value={message} maxLength={1000} 
                                                    placeholder="Type Your Message" className={messageErr?'err-input':''}
                                                    onChange={(e)=>{setMessage(e.target.value)}} autoComplete="off" rows={6} />
                                            </div>
                                            {validateSecurityTextPattern(message) ? <em className="color-red">{`special charatcters {<\\^/>} are invalid`}</em> : ''}
                                            {messageErr?<em className="color-red">{messageErr}</em>:''}
                                        </FormGroup>
                                        <FormGroup>                                            
                                            <div className="form-label">
                                                <Label data-for="captcha"><span>{`Captcha`}</span></Label>
                                            </div>
                                            <div className="form-input d-flex">
                                                <input id={`captcha`} name="captcha" value={captcha} placeholder="Enter Captcha" type={'text'} autoComplete="off"
                                                    onChange={(e)=>{setCaptcha(e.target.value)}} className={`captcha-input ${captchaErr?'err-input':''}`} />
                                                <div className="d-flex align-items-center">
                                                    <LoadCanvasTemplateNoReload />
                                                    <div className="btn btn-lg" onClick={() => loadCaptchaAgain()}>&#x21bb;</div> 
                                                </div>
                                            </div>
                                            {captchaErr?<em className="color-red">{captchaErr}</em>:''}
                                        </FormGroup>                         
                                        
                                    </div>
                                </div>
                                <div className="contact-btn-row py-3">                                         
                                    <Button size="md" color="primary" onClick={() => {handleSubmit()}} className="submit-bttn">Submit</Button>                           
                                    <Button size="md" color="secondary" onClick={() => {handleReset()}} className="reset-bttn">Reset</Button>                        
                                </div>
                            </Form>
                        </div>
                        {apiErr ? <div className="color-red err-row"><span>{apiErr}</span></div>: ''}
                    </div>
                </div>
                <div className="f-base"><PublicFooter></PublicFooter></div>
            </div>
            {showModal ? <PublicDataModal type={'C'} showFlag={showModal} sendClose={receiveModalInputs}></PublicDataModal>: ''}
        </>
    );
}

export default withRouter(PublicContactUs);


