import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import { getPublicVideoApi } from "./publicService";
import { apiBasePath } from "../../common/APIUtils";
import useScreenSize from "./utils/useScreenSize";

import './publicHomePage.scss';

// import vdPost from './images/vid-poster.svg';

import Spinner from "../../common/Spinner/Spinner";

const PublicHomePage = ({ loginClick, isPrivate = false }) => {

    const [activePage, setActivePage] = useState('H');
    const [isScrolledDown, setIsScrolledDown] = useState(false);

    const [videoSource, setVideoSource] = useState(null);
    const [slideCount, setSlideCount] = useState(2);
    const [caroArray, setCaroArray] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const screenSize = useScreenSize();
    let pubSlides = document.getElementsByClassName("slide-image");
    const publicationsArray = [
        {
            "image": '../../../assets/img/extPre/pub5.svg',
            "link": 'https://www.omfif.org/spi-journal_july24_ifc/',
            "title": 'Article: AI is driving ESG integration in emerging markets',
            "date": 'July, 2024'
        },
        {
            "image": '../../../assets/img/extPre/pub4.svg',
            "link": 'https://aclanthology.org/2022.nlp4pi-1.19/',
            "title": 'Conference Paper: Term Based Sentiment Analysis as applied to ESG concepts',
            "date": 'Dec, 2022'
        },
        {
            "image": '../../../assets/img/extPre/pub3.svg',
            "link": 'https://www.ifc.org/en/stories/2022/harnessing-ai-to-drive-better-investment-outcomes',
            "title": 'Blog Post: Harnessing AI to Drive Better Investment Outcomes',
            "date": 'Sep, 2022'
        },
        {
            "image": '../../../assets/img/extPre/pub2.svg',
            "link": 'https://www.e-elgar.com/shop/usd/handbook-of-environmental-impact-assessment-9781800379626.html',
            "title": 'Book Chapter: Machine Learning for efficient review of Environmental and Social Impact Assessments',
            "date": 'Sep, 2022'
        },
        {
            "image": '../../../assets/img/extPre/pub1.svg',
            "link": 'https://www.ifc.org/en/insights-reports/2021/publications-report-aisolutions',
            "title": 'Discussion Paper: Artificial Intelligence Solutions to Support Environmental, Social, and Governance Integration in Emerging Markets',
            "date": 'May, 2021'
        }               
        
    ];
    
    // const history = useHistory();
    // const location = useLocation();

    useEffect(() => {
        // add adobe analytics file
        document.getElementById('adb-analytics') != null && document.getElementById('adb-analytics').remove();
        const script = document.createElement("script");

        script.src = "./js/AnalyticsPublicHome.js";
        script.id = "adb-analytics";
        script.async = true;

        document.body.appendChild(script);
        // end of adobe analytics code
        
        if (window.location && window.location.pathname) {
            window.scrollTo(0, 0);
            if (window.location.pathname.includes('/home')) {
                setActivePage('H');
            } else if (window.location.pathname.includes('/about-malena')) {
                setActivePage('A');
            }
        }
        // loadServices();
        handleScroll();
        window.addEventListener("scroll", handleScroll, {passive: true});
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (screenSize && screenSize.width && publicationsArray) {
            let ceilValue = 2;
            if (screenSize.width < 767) {
                ceilValue = Math.ceil(publicationsArray.length / 1);
            } else if (screenSize.width < 1025) {
                ceilValue = Math.ceil(publicationsArray.length / 2);
            } else {
                ceilValue = Math.ceil(publicationsArray.length / 4);
            }
            setSlideCount(ceilValue);
        }
    }, [screenSize]);

    useEffect(() => {
        if (slideCount && publicationsArray) { // means ..need to change the carousle width
            let temp = [];
            if (slideCount === 2) {
                // divide pubarray into 2 and assign to caroArray               
                for (let index = 0; index < slideCount; index++) {
                    let each = {};
                    each['name'] = `4 pubs slide ${index+1}`;
                    each['itemsPerSlide'] = 4;
                    each['items'] = index === 0 ? publicationsArray.slice(index, 4): publicationsArray.slice(4);
                    temp.push(each);
                }               
            } else if (slideCount === 3) {
                // divide pubarray into 3 and assign to caroArray
                for (let index = 0; index < slideCount; index++) {
                    let each = {};
                    each['name'] = `2 pubs slide ${index+1}`;
                    each['itemsPerSlide'] = 2;
                    each['items'] = index === 0 ? publicationsArray.slice(index, 2) : index === 1? publicationsArray.slice(2,4): publicationsArray.slice(4);
                    // each['items'] = publicationsArray.slice(index*3 > (publicationsArray.length-1) ? (publicationsArray.length-1): index*3 , 2+(index*3) > publicationsArray.length ? publicationsArray.length:2+(index*3));
                    temp.push(each);
                }
            } else if (slideCount === 5) {
                for (let index = 0; index < slideCount; index++) {
                    let each = {};
                    each['name'] = `1 pub slide ${index+1}`;
                    each['itemsPerSlide'] = 1;
                    each['items'] = [];
                    each.items.push(publicationsArray[index]);
                    // each['items'] = publicationsArray.slice(index*3 > (publicationsArray.length-1) ? (publicationsArray.length-1): index*3 , 2+(index*3) > publicationsArray.length ? publicationsArray.length:2+(index*3));
                    temp.push(each);
                }
            }
            setCaroArray(temp);
            setCurrentSlide(0);
        }
    }, [slideCount]);   

    const loadServices = () => {


        // call video api
        // console.log('--- before video api --- time = ' + new Date());
        getPublicVideoApi().then(res => {
            if (res && res.status === 200) {

                // console.log('--- video api success,  gives data --- with time = ' + new Date());

                let sample = window.URL.createObjectURL(new Blob([res.data]));

                setVideoSource(sample);

            } else {
                setVideoSource(0);
            }
        }).catch(err2 => {
            // console.log(err2? err2.status: err2);

            setVideoSource(0);

        });
    }   
    
    const handleCaroClick = (type, val) => {
        if (!pubSlides) {
            pubSlides = document.getElementsByClassName("slide-image");
        }
        
        if (type === 'P' && pubSlides) {
            let newIndex = (currentSlide === 0 ? caroArray.length - 1 : currentSlide - 1);
            pubSlides[currentSlide].className = "slide-image rightOut";
            pubSlides[newIndex].className= "slide-image rightIn";
            setCurrentSlide(newIndex);
        } else if (type === 'N' && pubSlides) {
            let newIndex = (currentSlide === caroArray.length - 1 ? 0 : currentSlide + 1);
            pubSlides[currentSlide].className = "slide-image leftOut";
            pubSlides[newIndex].className= "slide-image leftIn";
            setCurrentSlide(newIndex);
        } else if (type === 'B' && pubSlides) {  // clicked on bubbles
            let jumpIndex = parseInt(val);
            if(jumpIndex === currentSlide) return;
            if(jumpIndex - currentSlide >= 0) {
              pubSlides[currentSlide].className = "slide-image leftOut";
              pubSlides[jumpIndex].className = "slide-image leftIn";
            } else {
              pubSlides[currentSlide].className = "slide-image rightOut";
              pubSlides[jumpIndex].className = "slide-image rightIn";
            }
            //updateBubbles(jumpIndex);
            setCurrentSlide(jumpIndex);
        }
    }

    const handleScroll = () => {
        const scrollPosition = window.scrollY; // => scroll position
        // console.log(scrollPosition);
        if (scrollPosition && scrollPosition > 40) {
            setIsScrolledDown(true);
        } else {
            setIsScrolledDown(false);
        }
    };

    const disableRightClick = (event) =>{
        event.preventDefault();
        return false;
    }

    return (
        <div className="pub-home-main">
            {isPrivate ? '' :
                <div className="header-encloser">
                    <header>
                        <PublicHeader loginC={loginClick} applyWhite={isScrolledDown} activePageName={activePage}></PublicHeader>
                    </header>
                </div>
            }           

            <main>
                <div className="main-head-wrapper" style={{ background: `url('../../../assets/img/extPre/head-bg.svg') no-repeat 100% bottom, url('../../../assets/img/extPre/bg-overlay.svg') no-repeat 100% bottom` }}>
                    <div className="side-angle"
                        style={{ background: `url("../../../assets/img/extPre/side-angle-1.svg") no-repeat 80%` }}
                    >&nbsp;</div>
                    <div className="head-laptop" style={{ background: `url('../../../assets/img/extPre/head-laptop.svg') no-repeat 100%` }}>
                        <div className="lap-wrap">
                            <div className="laptop-arcs-1">
                                <svg>
                                    <circle cx="99%" cy="44%" r="69%" className="dashed" />
                                </svg>
                            </div>
                            <div className="laptop-arcs-2">
                                <svg>
                                    <circle cx="41%" cy="159%" r="73%" className="dashed" />
                                </svg>
                            </div>
                            <div className="laptop-arcs-3">
                                <svg>
                                    <circle cx="32%" cy="37%" r="59%" className="dashed" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="title-content">
                        <div className="title-enc">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="main-h-1">{`MALENA: leverage the power of data and AI for sustainable investing.`}</h1>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <h3 className="sub-h-3">{`MALENA is an Artificial Intelligence analyst that extracts meaningful insights from unstructured environmental, social and governance (ESG) data at scale, enabling rapid analysis, increasing productivity, and enhancing investor confidence.​`}</h3>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <h3 className="sub-h-3">{`MALENA is the only AI-powered solution tailored to emerging markets. MALENA is available as a free public good.`}</h3>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="main-work-demo-wrapper row">
                    <div className="how-works col-lg-7 col-md-7 col-sm-12">
                        <h2 className="main-h-2">{`Who is MALENA For?`}</h2>
                        <div className="img-data-binder row no-gutters">
                            <div className="data-div col-5">
                                <div className="bind">
                                    <div className="row head">
                                        <span className="ico" style={{ width: '35px' }}>
                                            <img alt="investors and financial institutions" src="../../assets/img/extPre/ifc-exp.svg" width="35" height="35"></img>
                                        </span>
                                        <span style={{ width: 'calc(100% - 35px)' }} className="pl-2">{`Investors and financial institutions `}</span>
                                    </div>
                                    <div className="row data">
                                        <p align="left">{`Asset managers, banks, private equity firms, project finance lenders, insurance companies, export credit agencies, and stock exchanges can rapidly screen annual, sustainability, integrated, and impact reports and other ESG-related disclosures, enabling faster due diligence and efficient portfolio management.`}</p>
                                    </div>                                </div>
                                <div className="bind sec pt-3 pb-3">
                                    <div className="row head">
                                        <span className="ico">
                                            <img alt="regulators" src="../../assets/img/extPre/ifc-reg.svg" width="35" height="35"></img>
                                        </span>
                                        <span className="pl-2">{`Regulators`}</span>
                                    </div>
                                    <div className="row data">
                                        <p align="left">{`Banking regulators, capital market authorities, and environment ministries can assess ESG disclosures at scale from regulated entities, enabling faster turnaround times and better oversight.`}</p>
                                    </div>
                                </div>
                                <div className="bind">
                                    <div className="row head">
                                        <span className="ico">
                                            <img alt="data providers​" src="../../assets/img/extPre/ifc-data.svg" width="35" height="35"></img>
                                        </span>
                                        <span className="pl-2">{`Data providers​`}</span>
                                    </div>
                                    <div className="row data">
                                        <p align="left">{`ESG news and analysis providers can analyze unstructured text and generate insights, enhancing their data offerings.`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="img-div col-7">

                                <div className="out-cir">
                                    <div className="arc">
                                        <div className="arc-in">
                                            <div className="dot-1">
                                                <div className="dot-in">
                                                    <div className="dash-1"></div>
                                                </div>
                                            </div>
                                            <div className="dot-2">
                                                <div className="dot-in">
                                                    <div className="dash-2"></div>
                                                </div>
                                            </div>
                                            <div className="dot-3">
                                                <div className="dot-in">
                                                    <div className="dash-3"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inn-cir">
                                        <div className="logo-img">
                                            <span><img width="195" height="47" alt="MALENA works" src="../../assets/img/extPre/big-logo.svg"></img></span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="all-img">
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="demo-encloser col-lg-5 col-md-5 col-sm-12">
                        <div className="row demo-rel">
                            <div className="demo-binder"></div>
                        </div>

                        <h2 className="main-h-2">{`Introduction to MALENA`}</h2>
                        {/* <div className="row vid-p">
                            <p align="justify">{`You can go through this video to understand how MALENA operates`}</p>
                        </div> */}
                        <div className="vid-div">

                            <video className="malena-vid" width="100%"
                            data-customlink="nl: public home page video"
                            data-text="public_page_video_link"
                                poster={"../../assets/img/extPre/new-thumb.png"}
                                onContextMenu={(e) => disableRightClick(e)}
                                controlsList="nodownload"
                                muted
                                preload="auto"
                                controls>
                                <source
                                    // src={videoSource ? videoSource : null} 
                                    src={`${apiBasePath}/malena/video`}
                                    // src="https://drive.google.com/file/d/1t1j18df77GneOB7F_OGJceIXTHYIQnjr"
                                    // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
                                    type="video/mp4"></source>
                                <span>{`Sorry, your browser doesn't support embedded videos.`}</span>
                            </video>
                            {videoSource === 0 ?
                                <div className="err-text">
                                    <div className="d-flex justify-content-center">{`We've encountered an error.`}</div>
                                    <div className="d-flex justify-content-center">{`Please try again in a few minutes.`}</div>
                                </div>
                                : ''
                                // <div 
                                // // style={{ paddingTop: '5rem' }}
                                // className="vid-loader"
                                // style={{background: `url('../../../assets/img/extPre/new-thumb.png') no-repeat`}}
                                // >
                                //     <div className="vid-spin-div"><Spinner></Spinner></div>
                                // </div>
                            }
                        </div>
                        <div className="right-side-angle" style={{ background: `url('../../../assets/img/extPre/right-angle.svg') no-repeat 100%` }}></div>
                    </div>
                </div>
                {/* input,output row */}
                <div className="main-pub-event-wrapper row">
                    <div className="events-row">
                        <div className="row pe-title"><div className="col-12"><h2 className="main-h-2">{`Events`}</h2></div></div>
                        <div className="row event-vids">
                            <div className="each-vid col-lg-6 col-md-6 col-sm-12">
                                <div className="frame">
                                    <iframe width="100%" height="140" title="MALENA at Databricks Data + AI Summit 2024"
                                        src="https://www.youtube.com/embed/TreGBynGmaA?si=kxCY3pTvv5YYFm07"
                                        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                        style={{ border: 0 }} allowFullScreen id="yt_player_0"></iframe>
                                </div>
                                <div className="vid-title">
                                    <span className="vid-img"><img width="35" height="35" alt="data-bricks" src="../../../assets/img/extPre/data-bricks.svg"></img></span>
                                    <div className="vid-text">
                                        <span>{`MALENA at Databricks Data + AI Summit 2024`}</span>
                                        <div className="vid-date"><span>{`June, 2024`}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="each-vid col-lg-6 col-md-6 col-sm-12">
                                <div className="frame">
                                    <iframe width="100%" height="140" title="Champion Colleague - Atiyah Curmally"
                                        src="https://www.youtube.com/embed/6M8RyJyBSp8?si=T1ejmYWWLV1KOFuB"
                                        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                        style={{ border: 0 }} allowFullScreen id="yt_player_1"></iframe>
                                </div>
                                <div className="vid-title">
                                    <span className="vid-img"><img width="35" height="35" alt="data-bricks" src="../../../assets/img/extPre/ifc-vid.svg"></img></span>
                                    <div className="vid-text">
                                        <span>{`Champion Colleague - Atiyah Curmally`}</span>
                                        <div className="vid-date"><span>{`May, 2024`}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="each-vid col-lg-6 col-md-6 col-sm-12">
                                <div className="frame">
                                    <iframe width="100%" height="140" title="IFC's MALENA Provides Analytics for ESG Reviews in Emerging Markets Using NLP and LLMs"
                                        src="https://www.youtube.com/embed/UmBQzhdj_B8?enablejsapi=1&rel=0&showinfo=0" style={{ border: 0 }} allowFullScreen id="yt_player_2"></iframe>
                                </div>
                                <div className="vid-title">
                                    <span className="vid-img"><img width="35" height="35" alt="data-bricks" src="../../../assets/img/extPre/data-bricks.svg"></img></span>
                                    <div className="vid-text">
                                        <span>{`MALENA at Databricks Data + AI Summit 2023`}</span>
                                        <div className="vid-date"><span>{`June, 2023`}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="each-vid col-lg-6 col-md-6 col-sm-12">
                                <div className="frame">
                                    <iframe width="100%" height="140" title="IFC's MALENA Provides Analytics for ESG Reviews in Emerging Markets Using NLP and LLMs"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        src="https://www.youtube.com/embed/MP7n-Wbb8ik?start=386&end=1318&enablejsapi=1&rel=0&showinfo=0" style={{ border: 0 }} allowFullScreen id="yt_player_3"></iframe>
                                </div>
                                <div className="vid-title">
                                    <span className="vid-img"><img width="35" height="35" alt="united nations" src="../../../assets/img/extPre/un-logo.svg"></img></span>
                                    <div className="vid-text">
                                        <span>{`MALENA at United Nations World Data Forum`}</span>
                                        <div className="vid-date"><span>{`May, 2023`}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pub-row">
                        <div className="row pe-title">
                            <div className="col-8"><h2 className="main-h-2">{`Publications`}</h2></div>
                            <div className="col-4 car-btns">
                                <span className="prev"><img onClick={() => {handleCaroClick('P')}}  width="30" height="30" src="../../../assets/img/extPre/car-prev.svg" alt="previous"></img></span>
                                <span className="next"><img onClick={() => {handleCaroClick('N')}} width="30" height="30" src="../../../assets/img/extPre/car-next.svg" alt="next"></img></span>
                            </div>
                        </div>
                        <div className="row pub-cards">
                            {/* put a loop to repeat */}
                            {slideCount && caroArray && caroArray.map((si, sInd) => (
                                <div className={`slide-image ${sInd === 0 ? 'first': ''}`} key={sInd}>
                                    {si.items && si.items.map((ele,ind) => (
                                         <div key={ind} className={`each-pub item-${si.itemsPerSlide}`}>
                                         <div className="pub-wrapper">
                                             <div><img width="250" height="140" src={ele.image} alt={`pub_${ind}`}></img></div>
                                             <div className="text"><a target="_blank" href={`${ele.link}`}>{`${ele.title}`}</a></div>
                                             <div className="date-text"><span>{`${ele.date}`}</span></div>
                                         </div>
                                     </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="bubbles-row row">
                            {caroArray && caroArray.map((si, sInd) => (
                                <span key={sInd} onClick={() => {handleCaroClick('B', sInd)}} className={`inner-bubble ${currentSlide === sInd ? 'active' : ''}`}></span>
                            ))}
                            <span></span>
                        </div>
                    </div>                  

                </div>

                {/* recognition row */}
                <div className="main-rec-wrapper">
                    <div className="row rec-head">
                        <div className="col-12">
                            <h2 className="main-h-2">{`Recognition`}</h2>
                        </div>
                    </div>
                    <div className="row years-row">
                        <div className="col-lg-6 col-md-12 col-sm-12 each-rec pb-4">
                            <div className="each-rec-tit">
                                <div className="rec-img t" style={{ background: `url('../../assets/img/extPre/sin_fin.svg') no-repeat 100%` }}></div>
                                <div className="data-wrap">
                                    <div className="rec-y"><span>Finalist &#124; 2023</span></div>
                                    <div className="rec-t">
                                        <a target="_blank" href="https://www.mas.gov.sg/-/media/mas/news/media-releases/2023/annex-a--about-global-fintech-hackcelerator-2023.pdf">
                                            <span>{`Singapore Fintech Festival`}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="rec-d">
                                <p align="left">{`MALENA was selected as a finalist for the AI in Finance Global Challenge 2023 under the Monetary Authority of Singapore’s Global FinTech Hackcelerator to produce innovative and market-ready AI solutions that can transform the financial services industry.`}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 each-rec pb-4">
                            <div className="each-rec-tit">
                                <div className="rec-img t" style={{ background: `url('../../assets/img/extPre/rec-2023.svg') no-repeat 100%` }}></div>
                                <div className="data-wrap">
                                    <div className="rec-y"><span>Finalist &#124; 2023</span></div>
                                    <div className="rec-t">
                                        <a target="_blank" href="https://www.databricks.com/blog/advancing-business-data-ai-announcing-finalists-2023-databricks-data-team-transformation-award">
                                            <span>{`Databricks Data Team Transformation Award`}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="rec-d">
                                <p align="left">{`MALENA was selected as a finalist for the Databricks 2023 Data Team Transformation Award, honoring data teams taking their businesses to the next level with data-driven transformation and accelerating operations that lead to clear, impactful results.`}</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 each-rec">
                            <div className="each-rec-tit">
                                <div className="rec-img s" style={{ background: `url('../../assets/img/extPre/rec-2022.svg') no-repeat 100%` }}></div>
                                <div className="data-wrap">
                                    <div className="rec-y"><span>Outstanding &#124; 2022</span></div>
                                    <div className="rec-t">
                                        <a target="_blank" href="https://ircai.org/top100/entry/malena-machine-learning-environment-social-and-governance-analyst-2/">
                                            <span>{`IRCAI GLOBAL TOP 100`}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="rec-d">
                                <p align="left">{`The International Research Centre on Artificial Intelligence (IRCAI) recognized MALENA as one of the Top 10 AI projects to address the Sustainable Development Goals in 2022.`}</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 each-rec">
                            <div className="each-rec-tit">
                                <div className="rec-img f" style={{ background: `url('../../assets/img/extPre/rec-2021.svg') no-repeat 100%` }}></div>
                                <div className="data-wrap">
                                    <div className="rec-y"><span>Promising &#124; 2021</span></div>
                                    <div className="rec-t">
                                        <a target="_blank" href="https://ircai.org/top100/entry/malena-machine-learning-environment-social-and-governance-analyst/">
                                            <span>{`IRCAI GLOBAL TOP 100`}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="rec-d">
                                <p align="left">{`The International Research Centre on Artificial Intelligence (IRCAI) recognized MALENA as one of the Top 100 AI projects to address the Sustainable Development Goals in 2021.`}</p>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className="main-part-wrapper">

                    <div className="part-row row">
                        <div className="col-12 part-head">
                            <h2 className="main-h-2">{`In partnership with`}</h2>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="each-part">
                                <div className="p-wrap den">
                                    <span><img width="275" height="63" src="../../../assets/img/extPre/den-l.svg" alt="ministry of foreign affairs denmark partner"></img></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 sec">
                            <div className="each-part sec">
                                <div className="p-wrap kor">
                                    <span className="k"><img width="106" height="70" src="../../../assets/img/extPre/kor-l.svg" alt="korea"></img></span>
                                    <span className="minstry"><img width="102.20" height="100" src="../../../assets/img/extPre/min-l.svg" alt="ministry of economy and finace korea partner"></img></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 thi">
                            <div className="each-part">
                                <div className="p-wrap fias">
                                    <span><img width="258" height="73" src="../../../assets/img/extPre/fias-l.svg" alt="fias partner"></img></span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="bg-globe" style={{ background: `url('../../../assets/img/extPre/bg-globe.svg') no-repeat 100%` }}></div>
                </div> */}
            </main>
            {isPrivate ? '' :
                <div className="footer-encloser">
                    <footer>
                        <PublicFooter></PublicFooter>
                    </footer>
                </div>}
        </div>
    )
}

export default withRouter(PublicHomePage)