import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Spinner from './common/Spinner';
import './App.scss';
import DataProvider from './common/Context/DataContext';
// Login Page
// const Login = React.lazy(() => import('./components/Login'));
// Container
const Layout = React.lazy(() => import('./containers/Layout'));
const PublicWaitList = React.lazy(() => import('./components/PreLogin/PublicWaitList'));


class App extends Component {

  render() {
    return (
      <DataProvider value={{}}>
        <HashRouter>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {/* <Route path="/login" name="Login Page" render={props => <Login {...props}/>} /> */}
              <Route path="/public/wait-list" name="wait-list"
                render={props => <PublicWaitList {...props} {...this.props}></PublicWaitList>}>
              </Route>
              {this.props.isWaitListed ?
                <Redirect to="/public/wait-list"></Redirect> : ''

              }
              <Route path="/" name="Home" render={props => <Layout {...props} {...this.props} />} />
            </Switch>
          </Suspense>
        </HashRouter>
      </DataProvider>
    );
  }
}

export default App;
