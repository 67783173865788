import React, {useEffect} from "react";
import { withRouter, Link } from "react-router-dom";

import './termsOfUse.scss';

const TermsAndConditions = ({ type = 'page', subType }) => {

    useEffect(() => {
        // start of adobe analytics logic
        document.getElementById('adb-analytics') != null && document.getElementById('adb-analytics').remove();
        const script = document.createElement("script");
        script.src = "./js/AnalyticsTerms.js";
        script.id = "adb-analytics";
        script.async = true;
        document.body.appendChild(script);
        // end of adobe analytics logic

    }, []);

    const openNewWindow = (val) => {
        if (val) {
          window.open(`${window.location.origin}/#/${val}`);
        }    
    }

    return (
        <div className={`tc-pp-cs-main ${type}-type`}>
            <section className="tc-sec">
                {type === 'page' ? <h4 style={{ color: '#002245' }}>MALENA Website/App Terms of Use (Updated on August 2024)</h4> : ''}
                <p style={{ fontSize: '14px' }}>Dated: December 2023</p>
                <p style={{ fontSize: '14px' }}>Last Modified: August 2024</p>
                <p style={{color: '#2F5496'}}><strong>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE YOU START TO USE THE SITE.</strong></p>
                <p><strong className="under-line">Acceptance of these Terms of Use</strong><br />
                    By using MALENA, which means the MALENA ESG Sentiment Analysis model and any related MALENA product offering that IFC makes available for use, such as (but not limited to) web-based user interfaces, 
                    and any other related products or models, all of which are operated by International Finance Corporation ("<strong>IFC</strong>", "<strong>we</strong>" or "<strong>us</strong>"), you agree to be bound by the following 
                    terms and conditions, together with any documents expressly referred to within (collectively, "<strong>Terms of Use</strong>"). These Terms of Use explain how you may access and use this site and/or any related site 
                    (such as the MALENA App or other mobile application) that links to this site, including any content, functionality and services offered on or through the site(s) (the "<strong>Site</strong>").
                </p>
                <p>If you do not agree to these Terms of Use, the <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/privacy-policy": "static/privacy-policy"}`)}} className="tr-links">Privacy Notice</span></strong> and/or 
                    the <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/copyright-statement":"static/copyright-statement"}`)}} className="tr-links">Copyright and Disclaimer Notice</span></strong>, <strong><u>you must not access or use the Site</u>.</strong>
                </p>
                <p><strong className="under-line">Changes to the Terms of Use</strong><br />
                    We may change and/or update these Terms of Use from time to time without notice by amending this page. In the event of any changes, the revised Terms of Use will be posted on the Site and your continued use of the Site following such changes means that you accept and agree to the changes. Please check this page from time to time to take notice of any changes we have made, as you will be deemed to have accepted, and will be bound by, the revised terms through your continued use of the Site.
                </p>
                <p>These terms were most recently updated in August 2024.</p>
                <p><strong className="under-line">Access to and Availability of the Site</strong><br />
                    To access the Site or any of the resources it offers, you have to be an individual (“<strong>Authorized User</strong>”) and registered by providing an email address. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete. You are responsible for making sure that any account details are kept secure and confidential.
                </p>
                <p>If you know or suspect that anyone other than you knows any of your account details, you must promptly notify us at <a data-text="terms_conditions_contact_us_email_link" data-customlink="em: terms conditions contact-us link" href={`mailto: malena@ifc.org`} className="tr-links">malena@ifc.org</a>.</p>
                <p>You are responsible for:
                    <ul>
                        <li>Making all arrangements necessary for you to have access to the Site.</li>
                        <li>Ensuring that all persons who access the Site through your internet connection or device are Authorized Users and aware of these Terms of Use and comply with them.</li>
                    </ul>
                </p>
                <p>You acknowledge you use the Site at your own initiative and are responsible for compliance with local laws where they apply. IFC has the right to impose limits and/or conditions (including, without limitation, payment requirements) on the number and size of documents/data that can be uploaded to the Site.</p>
                <p>We may withdraw or amend this Site, and any service or material we provide on the Site, at any time as we see fit. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site.</p>
                <p>We are not obliged to permit anyone to register with the Site and have the right to refuse registration or disable any user name or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
                <p><strong className="under-line">Privacy</strong><br />
                    Your privacy and personal information are important to us. You agree that all information you provide to register with this Site or otherwise, including but not limited to through the use of any interactive features, and all information we collect on this Site is subject to our <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/privacy-policy": "static/privacy-policy"}`)}}  className="tr-links">Privacy Notice</span></strong>. By using the Site, you consent to all actions we take with respect to your information consistent with our Privacy Notice.
                </p>
                <p><strong className="under-line">Intellectual Property Rights</strong><br />
                    The Site and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by IFC, its affiliates, business partners, service providers, licensors or other providers of such material and are protected by United States copyright, trademark, patent, trade secret or other intellectual property or proprietary rights laws as well as applicable laws in other jurisdictions worldwide.
                </p>
                <p>Please note that a third party software has been used in the development of MALENA. Please see <a href="https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fgithub.com%2Ffacebookresearch%2Ffairseq%2Fblob%2Fmain%2FLICENSE&data=05%7C01%7Cbmcginley%40ifc.org%7C6bf61f81984842a9734408dbf05e9324%7C31a2fec0266b4c67b56e2796d8f59c36%7C0%7C0%7C638368061287958382%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=vnFUBd%2F4ggzUff%2BZ2%2ByVQKGNfixzUrhdjwN2m4aGGPc%3D&reserved=0" target="_blank" className="tr-links">fairseq/LICENSE at main · facebookresearch/fairseq · GitHub</a>.</p>
                <p>Your use of the intellectual property is expressly made subject to these Terms of Use. A failure to comply with these Terms of Use will result in the automatic termination of the License (as defined in the “Trademarks and License” section).</p>
                <p><strong className="under-line">Use of the Site</strong><br />
                It is your responsibility to ensure the input data is passed on to MALENA in the appropriate format. IFC will use reasonable endeavors to run MALENA such that the output data is in the appropriate format, which includes, but is not limited to, the input text split into sentences, and for each sentence, the risk terms detected by the model, and their respective sentiment predictions. IFC will not be responsible if the output data is not in an appropriate format due to any reason whatsoever, including (without limitation) if caused in whole or in part by your failure to provide the input data in an appropriate format. IFC shall have no liability for any losses suffered by you as a result of or in connection with any failure to provide or re-run MALENA with output in the appropriate format.</p>
                <p>The data collected from an Authorized User (not including Feedback, as defined in the “Feedback” section) (“<strong>Input Data</strong>”) will be retained by IFC for a period of six (6) months (“<strong>Retention Period</strong>”). Thirty (30) days prior to the date of expiry of each Retention Period, IFC shall issue a prompt to the Authorized User requesting the Authorized User’s affirmation as to what Input Data should be retained for a further six (6) month period.  If the Authorized User does not respond affirmatively to this prompt within thirty (30) days of receipt of the prompt, all of the Authorized User’s Input Data shall be deleted on the date of expiry of the Retention Period. On receipt by IFC of an affirmative response to the IFC prompt, IFC shall extend the Retention Period by a further six-month period. 
                    In the event a MALENA account has been inactive for a period of one year (“<strong>Inactive Account</strong>”), any Input Data (including any uploaded files) for the Inactive Account shall be deleted. IFC will use reasonable endeavors to issue a prompt to a user of an Inactive Account reasonably in advance of such deletion to enable such user to re-activate the Inactive Account. To the fullest extent permitted by applicable law, IFC shall have no liability to any user or third party in the event that IFC does not notify a user that the Input Data for its Inactive Account shall be deleted.
                </p>
                <p>We may provide you with technical support for using MALENA, but we are under no obligations to provide support and if we do so, we may stop providing that support at any time.</p>
                <p>You must not delete or alter any copyright, trademark or other proprietary rights notices from copies of materials that you receive from the Site.</p>
                <p>If you provide any other person with access to any part of the Site in breach of these Terms of Use, your right to use the Site will cease immediately.</p>
                <p>No right, title or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by IFC. Any use of the Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may infringe copyright, trademark and other laws.</p>
                <p><strong className="under-line">Use of Microsoft Azure</strong><br />
                    We use Microsoft Azure to host the Site.  Through your use of the Site, you agree you have read and understood, and agree to be bound by, the Microsoft Azure terms of use available here: Microsoft Product Terms, which may be updated by Microsoft from time to time, and that you will comply with these terms at all times when accessing and/or using the Site.
                </p>
                <p><strong className="under-line">Data Protection and Confidential Information</strong><br />
                    IFC, a member of the World Bank Group (“<strong>WBG</strong>”), is not bound by local privacy laws nor local laws relating to data breaches but rather by policies and procedures put in place by the WBG. As such, IFC’s notification obligations in the event of a data breach shall be governed by WBG policies and procedures and not local law.</p>
                <p>IFC makes no guarantee of confidentiality for any information (including, but not limited to information that you upload onto the Site) or with respect to any information that may be provided to you by the Site, and you upload any confidential or sensitive information at your own risk.</p>
                <p><strong className="under-line">Feedback</strong><br />
                    The Site will enable you to provide input on the Site and the services provided. If you send or transmit to IFC through the Site or by any other means any communications, comments, questions, suggestions or related materials (collectively “<strong>Feedback</strong>”) suggesting or recommending changes to the Site or any services offered through the Site, including, without limitation, new features or functionality relating thereto, you agree that all such Feedback shall be treated by you as confidential information. 
                    Unless prohibited by applicable law, you hereby assign to IFC all right, title and interest in, and IFC is free to use, without attribution or compensation to you, any ideas, know-how, concepts, techniques or other intellectual property and proprietary rights contained in the Feedback, whether or not patentable. Where an assignment of your Feedback is prohibited by law, you hereby grant us an exclusive, transferable, world-wide, sublicensable, perpetual, irrevocable, royalty free and fully paid-up license to use, copy, 
                    reproduce, adapt, modify, perform, display, distribute, offer to sell, sell, rent, lease, sub-license, share, make derivative works of and commercialize your Feedback and any derivative works thereof in any manner or medium, now or hereafter developed, and for any purpose IFC may determine in its sole discretion. Notwithstanding the foregoing, you understand and agree that IFC is under no obligation to use any Feedback received from you and you have no right to compel IFC to use any Feedback you provide.
                </p>
                <p><strong className="under-line">Screen Scraping</strong><br />
                You shall not conduct, facilitate, authorize or permit any text or data mining or web scraping in relation to the Site or any services provided via, or in relation to, the Site. This includes using (or permitting, authorizing or attempting the use of):
                </p>
                <ul>
                    <li>any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the Site or any data, content, information or services accessed via the Site; and/or</li>
                    <li>save as permitted under these Terms of Use, any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</li>
                </ul>
                <p>The provisions in this section should be treated as an express reservation of our rights in this regard.</p>
                <p>This section shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract.</p>
                <p><strong className="under-line">Trademarks and License</strong><br />
                    The names “International Finance Corporation” and “IFC”, the terms “MALENA,” any other word marks, any logos and all related names, logos, product and service names, designs and slogans are trademarks of IFC or its affiliates or licensors. You must not use such marks without IFC’s prior written permission. All other names, logos, product and service names, designs and slogans on this Site are the trademarks of their respective owners.
                </p>
                <p>IFC grants the Authorized User a non-exclusive, non-transferable, non-sublicensable, non-assignable, royalty-free, revocable licence, only whilst the Agreement (as defined in the “Term, Monitoring and Enforcement” section) is in full force and effect, to access and use MALENA, solely for internal use by the Authorized User (the “<strong>License</strong>”).</p>
                <p>Your use of the marks is expressly made subject to these conditions, including without limitation the License conditions. A failure to comply with any of these conditions will result in the automatic termination of the License.</p>
                <p><strong className="under-line">Prohibited Uses</strong><br />
                    You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Site:
                    <ul>
                        <li>In any way that violates these Terms of Use and/or which violates any applicable national, state, provincial, local or international law or regulation (including, without limitation, any laws regarding the export of data or software).</li>
                        <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail", "chain letter" or "spam" or any other similar solicitation.</li>
                        <li>To impersonate or attempt to impersonate IFC, an IFC employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any such persons or entities).</li>
                        <li>To engage in any other conduct that restricts or prevents anyone's use or enjoyment of the Site, or which, as determined by us, may harm IFC or users of the Site or expose them to liability.</li>
                        <li>In any way that may infringe the intellectual property rights (e.g., copyright, trademarks, service marks, patents, database rights, know-how, design rights, domain names, know-how and rights in software (registered or not)) or any other rights of anyone (including IFC).</li>
                    </ul>
                </p>
                <p>Additionally, you agree not to:
                    <ul>
                        <li>Upload any materials, documents or information onto the Site that contain any information that could be used or that would be likely to identify an individual, other than yourself, unless you have first received written permission from the specific individual to use that information that could identify him/her.</li>
                        <li>Upload any documents or other data or information to your account for the purpose of generating company profiles or document reviews unless you have obtained all necessary permissions to use the data or information in such documents for analysis using MALENA.</li>
                        <li>Upload any Material Nonpublic Information (MNPI).</li>
                        <li>Use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party's use of the Site, including any other party’s ability to engage in real time activities through the Site.</li>
                        <li>Reverse assemble, reverse compile, reverse engineer, decompile, translate or otherwise attempt to discover the source code of any component of MALENA or any underlying algorithms or access or use MALENA in order to replicate the software.</li>
                        <li>Modify, add to, or otherwise enhance any component of MALENA without IFC’s written consent.</li>
                        <li>Infringe any of IFC’s intellectual property rights including by copying our code or content or the design of MALENA.</li>
                        <li>Use any manual process to monitor or copy any of the material on the Site or for any other unauthorized purpose without our prior written consent.</li>
                        <li>Use any device, software or routine that interferes with the proper working of the Site.</li>
                        <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
                        <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Site, any server on which the Site is stored, or any server, computer or database connected to the Site.</li> 
                        <li>Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.</li>
                        <li>Otherwise attempt to interfere with the proper working of the Site.</li>
                    </ul>
                </p>
                <p><strong className="under-line">Term, Monitoring and Enforcement</strong><br />
                    These Terms of Use, the <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/privacy-policy": "static/privacy-policy"}`)}} className="tr-links">Privacy Notice</span></strong> and the <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/copyright-statement":"static/copyright-statement"}`)}} className="tr-links">Copyright and Disclaimer Notice</span></strong> (together, the “<strong>Agreement</strong>”) shall commence on the date on which You “Click to Accept” these Terms of Use, and shall continue in full force and effect indefinitely, unless the Agreement is terminated by IFC at its sole discretion.  
                    <p>We have the right but not the obligation to:
                        <ul>
                            <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                            <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site.</li> 
                            <li>Terminate or suspend your access to all or part of the Site for any or no reason, including without limitation, for any violation of these Terms of Use.</li>
                        </ul>
                    </p>
                </p>
                <p>On termination or suspension of your access to all or part of the Site for any or no reason, and/or on termination of the Agreement and/or any part of it (including without limitation termination of these Terms of Use), the License shall automatically terminate. Termination of the License shall not affect any accrued rights of IFC or obligations on You, and all intellectual property rights of IFC shall remain vested in IFC and shall not be affected by the termination of the License.</p>
                <p>Without limiting the above, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Site (the “<strong>Activities</strong>”). YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS IFC AND ITS AFFILIATES, BUSINESS PARTNERS, LICENSORS AND SERVICE PROVIDERS, AND THEIR RESPECTIVE SHAREHOLDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS FROM AND AGAINST ALL CLAIMS, ACTIONS, SUITS, LOSSES, COSTS, LIABILITIES, JUDGMENTS, DAMAGES AND EXPENSES, INCLUDING (WITHOUT LIMITATION) REASONABLE ATTORNEYS’ FEES AND COURT COSTS, ARISING OUT OF ANY ACTIVITIES.</p>
                <p>We cannot review material before it is posted on the Site and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or non-performance of the Activities described in this section.</p>
                <p><strong className="under-line">Reliance on Information and Results Posted</strong><br />
                    Any results provided to you through the Site are made available solely for general information purposes. We do not promise the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We accept no liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents. In particular, but not in any way by limitation of the foregoing, you acknowledge and agree that any results or output that you receive from MALENA will not include and shall not be deemed to constitute investment advice, legal or accounting advice or advice with respect to the compliance by you with any securities or company law and regulation, stock exchange requirements or any similar legislative, regulatory or contractual duties and obligations to which you may be bound; and that for advice with respect to such matters, you shall rely solely on your own legal advisors, accountants, auditors and other professional advisors authorized to provide such advice in the jurisdictions in which you conduct your activities. For the avoidance of doubt, any results or output that you receive from MALENA does not constitute a validation or endorsement by IFC and you will not represent or share it as such.
                </p>
                <p>From time to time, the Site may also include links to other websites and resources provided by third parties. These links are provided for your convenience and information only. They do not signify that we endorse the website(s) and should not be interpreted as approval by us of those linked sites or information you may obtain from them. We have no responsibility for the content of the linked website(s).</p>
                <p><strong className="under-line">Updates to the Site</strong><br />
                    Although we make reasonable efforts to update the information on this Site, we make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up to date.
                </p>
                <p>We are under no obligation to update any information contained on the Site.</p>
                <p><strong className="under-line">Disclaimer of Warranties</strong><br />
                    You understand that we cannot and do not guarantee or warrant that your use of the Site will not expose you to viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT.
                </p>
                <p>YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER IFC NOR ANY AFFILIATE, BUSINESS PARTNER, LICENSOR OR SERVICE PROVIDER OF IFC OR ANY SHAREHOLDER, EMPLOYER, AGENT, OFFICER OR DIRECTOR THEREOF, MAKES ANY WARRANTY OR REPRESENTATION TO YOU WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER IFC NOR ANY AFFILIATE, BUSINESS PARTNER, LICENSOR OR SERVICE PROVIDER OF IFC OR ANY SHAREHOLDER, EMPLOYER, AGENT, OFFICER OR DIRECTOR THEREOF, REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                <p>IFC MAKES NO WARRANTY, CONDITION OR REPRESENTION OF ANY KIND, EXPRESS OR IMPLIED AS TO ANY RESULTS THAT MAY BE ACHIEVED BY YOU AS A RESULT OF YOUR USE OR RELIANCE ON THE OUTPUT DATA, NOR AS TO THE SUFFICIENCY, ACCURACY OR FITNESS FOR PURPOSE OF ANY OUTPUT DATA.</p>
                <p>IFC HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
                <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                <p><strong className="under-line">Limitation on Liability</strong><br />
                    IN NO EVENT WILL IFC, ITS AFFILIATES, BUSINESS PARTNERS, AND SERVICE PROVIDERS OR ANY OF OUR AND THEIR RESPECTIVE LICENSORS, SHAREHOLDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS, BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY CONTENT ON THE SITE OR ANY SERVICES OR ANY SUPPORT OR ITEMS OBTAINED THROUGH THE SITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
                </p>
                <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                <p><strong className="under-line">Indemnification</strong><br />
                    <strong>You agree to defend, indemnify and hold harmless IFC, its affiliates, business partners, licensors and service providers, and their respective shareholders, employees, agents, officers or directors, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to any use of the Site's content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Site.</strong>
                </p>
                <p><strong className="under-line">Events Beyond our Control</strong><br />
                    We are not liable to you if we fail to comply with these Terms of Use because of circumstances beyond our reasonable control, including, but not limited to, strikes, lockouts or other industrial disputes; breakdown of systems or network access; flood, fire, explosion or accident; or epidemics or pandemics.
                </p>
                <p><strong className="under-line">Governing Law and Jurisdiction</strong><br />
                    All matters relating to the Site and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of New York, United States of America, without giving effect to any choice or conflict of law provision or rule (whether of the State of New York or any other jurisdiction).
                </p>
                <p>You agree that any legal suit, action or proceeding arising out of, or related to, these Terms of Use, the Privacy Notice or the Copyright and Disclaimer Notice or the Site shall be instituted exclusively in the federal courts of New York. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                <p><strong className="under-line">Waiver and Severability</strong><br />
                    No waiver by IFC of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of IFC to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.
                </p>
                <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of these Terms of Use will continue in full force and effect.</p>
                <p><strong className="under-line">Preservation of Immunities</strong><br />
                    Nothing herein shall constitute or be considered to be a limitation upon or a waiver of the privileges and immunities of IFC, which are specifically reserved.
                </p>
                <p><strong className="under-line">Rights of Third Parties</strong><br />
                    No one other than a party to these Terms of Use has any right to enforce any of these Terms of Use. 
                </p>
                <p><strong className="under-line">Assignment</strong><br />               
                    Authorized Users are not permitted to assign any of their rights or obligations under these Terms of Use without the prior written consent of IFC. Any purported assignment and delegation shall be null and void.
                </p>
                <p><strong className="under-line">Entire Agreement</strong><br />
                    These Terms of Use, our <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/privacy-policy": "static/privacy-policy"}`)}} className="tr-links">Privacy Notice</span></strong> and our <strong><span onClick={() => {openNewWindow(`${subType === 'P' ? "public/copyright-statement":"static/copyright-statement"}`)}} className="tr-links">Copyright and Disclaimer Notice</span></strong> constitute the sole and entire agreement between you and International Finance Corporation with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site. 
                </p>
                <p><strong className="under-line">Your Comments and Concerns</strong><br />
                    This Site is operated by International Finance Corporation, 2121 Pennsylvania Ave., NW, Washington, DC 20433, United States of America.
                </p>
                <p>All notices of copyright infringement claims should be sent to the address set forth in our <strong><span  onClick={() => {openNewWindow(`${subType === 'P' ? "public/copyright-statement":"static/copyright-statement"}`)}} className="tr-links">Copyright and Disclaimer Notice</span></strong>.</p>
                <p>All other feedback, comments, requests for technical support and other communications relating to the Site should be directed to: <a data-text="terms_conditions_contact_us_email_link" data-customlink="em: terms conditions contact-us link" href={`mailto: malena@ifc.org`}className="tr-links">malena@ifc.org</a>.</p>
                <p>Thank you for visiting the Site.</p>
            </section>
            {/* {type === 'page'  && subType !== 'P' ?
                <PublicFooter pType={"PR"}></PublicFooter>                
                 : ''
            } */}
        </div>
    );
}

export default withRouter(TermsAndConditions);