import React, { Component } from "react";
import { WindowUtils } from "msal";
import Spinner from '../common/Spinner';
import { PublicClientApplication,Logger, LogLevel, EventType } from '@azure/msal-browser';
import { HashRouter as Router, Switch, Route, Link  } from "react-router-dom";
import {
   // msalApp,
    config,
    GRAPH_REQUESTS,
    requiresInteraction
} from "./AuthUtil";
import { configAuth, powerbiAuth } from './AuthConstants';
import { getUserDetails } from './GraphService';
import { getExtConfigApi } from "../components/SelfService/SelfServiceApi";
import { getAppConfigs } from "../common/Utils"; 
import PublicHomePage from "../components/PreLogin/PublicHomePage";
import PublicPrivacyPolicy from "../components/PreLogin/PublicPrivacyPolicy";
import PublicTermsAndConditions from "../components/PreLogin/PublicTermsAndConditions";
import PublicCopyrightStatement from "../components/PreLogin/PublicCopyrightStatement";
import PublicRegistration from "../components/PreLogin/PublicRegistration";
import PublicLoginPre from "../components/PreLogin/PublicLoginPre";
import PublicAboutMalena from "../components/PreLogin/PublicAboutMalena";
import PublicContactUs from "../components/PreLogin/PublicContactUs";

const { clientId, tenantId } = getAppConfigs();

class AuthenticationWrapper extends Component {
    const = { publicClientApplication: PublicClientApplication };
    constructor(props) {
        super(props);

        this.state = {
            account: null,
            isRefreshingToken: false,
            isAuthenticated: false,
            accessToken: null,
            user: {},
            error: null,
            showSpinner: true,
            isConfigFailed: false,
            isWaitListedUser : false,
        };
        this.publicClientApplication = new PublicClientApplication(
            {
                auth: {
                    authority: configAuth.authority,
                    clientId: configAuth.appId,
                    redirectUri: window.location.origin
                },
                cache: {
                    
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true
                },
                system: {
                    navigateFrameWait: 500,
                    loggerOptions: {
                        logLevel: LogLevel.Verbose,
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    // console.error(message);
                                    return;
                                case LogLevel.Info:
                                    // console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    // console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    // console.warn(message);
                                    return;
                            }
                        },
                        piiLoggingEnabled: false
                    },
                    telemetry: {
                        applicationName: "malena",
                        applicationVersion: "3.1.0",
                        telemetryEmitter: (events) => {
                            //console.log('Telemetry Events:', events);
                        }
                    }
                }
            }
        );

        // Optional - This will update account state if a user signs in from another tab or window
        this.publicClientApplication.enableAccountStorageEvents();
        this.publicClientApplication.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
              const account = event.payload.account;
              this.publicClientApplication.setActiveAccount(account);
            }
          });
        //   UserAgentApplication.handleRedirectPromise((error, response) => {
        //     if (error || response) {

        //     }
        //   });
    }

    checkSession =() => {    
        let flag = false;
        
            let sessionObj = [];
            const temp = this.publicClientApplication;
            if(temp && temp.getAllAccounts()){
                sessionObj = temp.getAllAccounts();
                if (sessionObj && sessionObj.length === 0) {
                    flag= true;
                    sessionStorage.clear();
                }
            }
              
        return flag;
      }

    onSignOut() {
        if(this.checkSession()){
            window.location.reload();
        }else{
            
            const currAccount = this.publicClientApplication.getAllAccounts()[0];
            sessionStorage.clear();
            if(currAccount){
                this.publicClientApplication.logoutRedirect({account: currAccount});
            }else{
                this.publicClientApplication.logoutRedirect();
            }
        }  
        localStorage.setItem('ILU', false); // is logged in user      
    }    

    async signIn() {
         try {           
            // Login via redirect.. For popup, use loginPopup method
            await this.publicClientApplication.loginRedirect(                
                {
                    scopes: config.scopes,                    
                }
                );            
            
            // After login, get the user's profile from MS Graph
            this.getUserProfile();
        }
        catch (err) {
            this.setState({
                isAuthenticated: false,
                user: {},
                error: err
            });
        }
    }

    fetchExtConfig = async() => {
        let flag = true;
        
            // make a API call to get the config from API
            return getExtConfigApi().then(res => {
                if (res) {
                    // store this config in some browser storage
                    // and set the state of account : only after success...
                    // this.setState({
                    //     isConfigFailed: false,
                        
                    // });
                    
                    if (res.is_waitlisted) {
                        
                        this.setState({isWaitListedUser: true});
                        return false;
                    }
                    localStorage.setItem('cObj', JSON.stringify(res));
                    // if (res.user_type === 'B2B' || res.user_type === 'B2E') {
                    //     localStorage.setItem('isBeta', true);
                    // } else {
                    //     localStorage.setItem('isBeta', false);
                    // }
                    flag = true;
                    return true;
                    
                }
            }).catch(err => {                
                if (err && err.message) {
                    // 
                    let dummy = {
                        "is_waitlisted": false,
                        "embedUrlUpload": "https://app.powerbi.com/reportEmbed?reportId=356c2e34-3208-406d-8528-392c5b8be428&groupId=e0a76150-b40f-499f-946a-55c68d610224&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
                        "reportIdUpload": "356c2e34-3208-406d-8528-392c5b8be428",                        
                        "embedUrlChangeLog": "https://app.powerbi.com/reportEmbed?reportId=7901c49f-d7d9-42d1-8f9a-9cf4cd4e3d87&groupId=e0a76150-b40f-499f-946a-55c68d610224&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
                        "reportIdChangeLog": "7901c49f-d7d9-42d1-8f9a-9cf4cd4e3d87",
                        "is_disclaimer": true,
                        "supported_each_doc_size": 40,
                        "supported_total_size_per_upload": 100,
                    };
                    localStorage.setItem('cObj', JSON.stringify(dummy));
                    // 
                    flag = false;
                    return false;
                    
                    // let eObj = JSON.parse(err.message);
                    // if (eObj && eObj.status === 500) {
                    // }
                    // this.setState({
                    //     isConfigFailed: true                                
                    // });
                }
            });
        

        // return flag;
    }

    async getUserProfile() {
       
        // Below code is to get user details from MS Graph and set the states
        try {
            var configUser = {
                scopes: [
                    'User.Read'
                    // 'calendars.read'
                ],
                forceRefresh: false
            };
         
            var accessToken = await this.getAccessToken(configAuth);
            if(window.appConfigs.showPowerBI == true){
                var accessToken_pw = await this.getAccessToken(powerbiAuth);
                sessionStorage.setItem('access_pw', accessToken_pw.accessToken)
            }
           
            //console.log("accessToken   res", accessToken_pw);
            sessionStorage.setItem('access1', accessToken.accessToken)
            localStorage.setItem('ILU', true); // is logged in user
            
            if (accessToken) {
                //console.log("accessToken response", accessToken);

                const gotConfig = await this.fetchExtConfig();
                //Get the user's profile from Graph               
                var tokenResponse = await this.getAccessToken(configUser);
                if (tokenResponse.accessToken) {
                    // Get the user's profile from Graph
                    var user = await getUserDetails(tokenResponse.accessToken);
                   // console.log("getUserDetails response", user);
                    this.setState({
                      isAuthenticated: true,
                      user: user,
                      idTokenClaims: tokenResponse.idTokenClaims,
                      error: null,
                      isConfigFailed: !gotConfig
                    });
                    sessionStorage.setItem('user', JSON.stringify(user))
                  }


                
                var account = this.publicClientApplication.getAllAccounts()[0];            
                this.setState({account: account})
                var pathname = "esg-insights";
                this.props.history.push({
                    pathname: pathname,
                    state: {
                        isAuthenticated: true,
                        user: {
                            displayName: this.state.user.displayName,
                            email: this.state.user.mail || this.state.user.userPrincipalName
                        },
                        userAccount: account,
                        accessToken: accessToken.accessToken,
                        // accessTokenForSearch: accessToken_forseach.accessToken,
                        // accessToken_formlsvc: accessToken_formlsvc.accessToken,
                        //upi:this.state.userAccount.idToken.upi,
                        // FeatureFlags: sFeatureFlags,
                    }
                });  
                         
               
            }
            else {
                console.log("No accessToken response");
            }

        }
        catch (err) {
            // console.log(err ? err.response : err.data)
            // console.log(err ? err.status : err.error)
            this.setState({
                isAuthenticated: false,
                user: {},
                error: err
            });
        }

    }
    async getAccessToken(Request) {
        // console.log("getAccessToken Request" + Request);
        try {
            // Get the access token silently
            // If the cache contains a non-expired token, this function
            // will just return the cached token. Otherwise, it will
            // make a request to the Azure OAuth endpoint to get a token
            //console.log('account',this.publicClientApplication.getAllAccounts()[0]);
            var silentResult = await this.publicClientApplication.acquireTokenSilent({
                account: this.publicClientApplication.getAllAccounts()[0],
                scopes: Request.scopes
            });
            //console.log('Acquired access token silent at: ' + silentResult);
            return silentResult;
        }catch (err) {
            // If a silent request fails, it may be because the user needs
            // to login or grant consent to one or more of the requested scopes
            
                return 0;
            
            
        }
    }

    // async refreshToken() {
    //     const tokenResponse = await msalApp.acquireTokenSilent(GRAPH_REQUESTS.TOKEN_REFRESH).catch(error => {
    //         if (requiresInteraction(error.errorCode)) {
    //             return msalApp.acquireTokenRedirect(GRAPH_REQUESTS.TOKEN_REFRESH);
    //         } else {
    //             console.error('Non-interactive error:', error)
    //         }
    //     });

    //     if (tokenResponse) {
    //         // const token = tokenResponse.idToken.rawIdToken;
    //         // sessionStorage.setItem('msal.idtoken', token);
    //     }
    //     this.setState({ isRefreshingToken: false });
    // }

    async componentDidMount() {
        
        if (this.publicClientApplication) {
           
            if (this.publicClientApplication.getAllAccounts() &&
             this.publicClientApplication.getAllAccounts()[0]) {
                let silentRes = await this.getAccessToken(configAuth);
                // let silentRes = await this.publicClientApplication.acquireTokenSilent({
                //     account: this.publicClientApplication.getAllAccounts()[0],
                //     scopes: configAuth.scopes
                // });
                if (silentRes) {
                    let acc = this.publicClientApplication.getAllAccounts()[0];
                    this.getUserProfile();
                    this.setState({account: acc, showSpinner: false})
                } else if (silentRes === 0) { // experme code
                    localStorage.clear();
                    sessionStorage.clear();
                    this.setState({showSpinner: false});
                }
                 else {
                    this.setState({showSpinner: true});
                }
            } else {
                this.publicClientApplication.handleRedirectPromise().then((tokenResponse) => {
                    if (tokenResponse && tokenResponse.tokenType === "Bearer") {
                        this.getUserProfile();
                    } else {
                        // verify if user has any active session in other tab by using local storage variable ILU
                        if (localStorage.getItem('ILU') && localStorage.getItem('ILU') === 'true') {
                            // means, has active session in other tab
                            // trigger login again, it generates its own token then
                            // this.onLoginBtnClick();
                            this.signIn();

                        }
                        this.setState({showSpinner: false});
                    }
                }).catch(err => {
                    this.setState({showSpinner: false});
                });
                // this.setState({showSpinner: false});
            }
        }

    }

    onLoginBtnClick = (type) => {
        this.publicClientApplication.handleRedirectPromise().then((tokenResponse) => {
            var accountObj = tokenResponse ? tokenResponse.account : this.publicClientApplication.getAllAccounts()[0];
            if (accountObj) {
                //console.log("accountObj", accountObj);
                // Account object was retrieved, continue with app progress
                //console.log('id_token acquired at: ' + new Date().toString());
                sessionStorage.setItem('access1', tokenResponse ? tokenResponse.accessToken: "")
                this.getUserProfile();
            } else if (tokenResponse && tokenResponse.tokenType === "Bearer") {
                // No account object available, but access token was retrieved
                //console.log('access_token acquired at: ' + new Date().toString());
                this.getUserProfile();
            } else if (tokenResponse === null) {
                // tokenResponse was null, attempt sign in or enter unauthenticated state for app
                //console.log('signIn ');
                this.signIn();
            } else {
                 this.signIn();
                console.log("tokenResponse was not null but did not have any tokens: " + tokenResponse);
            }

        }).catch((error) => {
            // console.log('in getuserprofile error');
            // console.log(error ? error.data: error);
        });
        // if (type === 'L') {
           //  localStorage.setItem('showTOU', true);
            // localStorage.setItem('isBeta', false);
        // }
        
    }


    render() {
        const { account, isAuthenticated, showSpinner, isWaitListedUser } = this.state;
         if (!account ) {
            
             // here the prelogin page
             return ( showSpinner ? <Spinner></Spinner>:
             <Router>
                <Switch>
                    <Route path="/public/contact-us">
                        <PublicContactUs loginClick={this.onLoginBtnClick}></PublicContactUs>
                    </Route>
                    <Route path="/public/privacy-policy">
                        <PublicPrivacyPolicy loginClick={this.onLoginBtnClick}></PublicPrivacyPolicy>
                    </Route>
                    <Route path="/public/terms-conditions">
                        <PublicTermsAndConditions loginClick={this.onLoginBtnClick}></PublicTermsAndConditions>
                    </Route>
                    <Route path="/public/copyright-statement">
                        <PublicCopyrightStatement loginClick={this.onLoginBtnClick}></PublicCopyrightStatement>
                    </Route>
                    <Route path="/public/registration">
                        <PublicRegistration loginClick={this.onLoginBtnClick}></PublicRegistration>
                    </Route>
                    <Route path="/public/login-pre">
                        <PublicLoginPre loginClick={this.onLoginBtnClick}></PublicLoginPre>
                    </Route>
                    <Route path="/public/about-malena">
                        <PublicAboutMalena isLoggedIn={false}></PublicAboutMalena>
                    </Route>
                    <Route path="/">
                        <PublicHomePage loginClick={this.onLoginBtnClick}/>
                    </Route>
                </Switch>                
             </Router>                 
             )
         }

        const App = this.props.app;
        return (
                <App
                        {...this.props}
                        account={this.state.account}
                        error={this.state.error}
                        onSignOut={() => this.onSignOut()}
                        pcApplication={this.publicClientApplication}  
                        isWaitListed={isWaitListedUser}                      
                    />
                );
    }
};

export default AuthenticationWrapper;
