import React from "react";
import { useState } from "react";
import { Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import './ContactUs.scss';

const ContactUs = ({ showFlag, contactClickHandler, successFlag, requestId }) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage ] = useState('');
    
    const handleClick = (type) => {
        contactClickHandler(type, {subject:subject, message: message});
    };

    const validateInputs = () => {
        if (subject && message){
            return true;
        } else {
            return false;
        }
    }

    const validateTextPattern = (input) => {
        let pattern = /[<{^>}]/;
        return pattern.test(input);
    }

    const validateFormInputs = () => {
        if (validateInputs(subject) && !validateTextPattern(subject) 
        && validateInputs(message) && !validateTextPattern(message)) {
            return true;
        } else return false;
    }

    return (
        <div className="contact-modal-main">
            <Modal className="modal-wrapper contact-ext-modal" isOpen={showFlag} centered={false}>
                {!successFlag?
                    <>
                    <ModalHeader className="modal-heading"><span>{`Contact Us`}</span></ModalHeader>
                    <ModalBody className="contact-form-wrapper"> 
                        <Form tag={`contact-us-form`}>
                            <div className="contact-form">
                                <div className="row a-f-m pb-2">
                                    <div className="col-12 d-flex justify-content-end">
                                        <span>{`All Fields are mandatory`}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <FormGroup>
                                            <div className="form-label">
                                                <Label data-for="contact_subject"><span>{`Subject`}</span></Label>
                                            </div>
                                            <div className="form-input">
                                                <input id={`contact_subject`} name="contact-subject"
                                                    value={subject} type={'text'} 
                                                    autoComplete='off' maxLength={500}
                                                    onChange={(e)=>{setSubject(e.target.value)}}
                                                    placeholder="Enter Subject" 
                                                />
                                            </div>
                                            {validateTextPattern(subject) ? <em className="color-red">{`special charatcters {<\\^/>} are invalid`}</em> : ''}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <FormGroup>
                                            <div className="form-label">
                                                <Label data-for="contact_message"><span>{`Message`}</span></Label>
                                            </div>
                                            <div className="form-input">
                                                <textarea id={`contact_message`} name="contact-message"
                                                    value={message} rows={3}
                                                    autoComplete='off'
                                                    onChange={(e)=>{setMessage(e.target.value)}}
                                                    placeholder="Type your message" 
                                                />
                                            </div>
                                            {validateTextPattern(message) ? <em className="color-red">{`special charatcters {<\\^/>} are invalid`}</em> : ''}
                                        </FormGroup>
                                    </div>
                                </div>                                                    
                            </div>
                        </Form>            
                    </ModalBody>
                    <ModalFooter>
                        <div className="row btn-row">
                            <div className="col-12 d-flex justify-content-between">
                                <Button onClick={() => handleClick('Y')} className="mr-3" 
                                    color="primary" size="lg" disabled={!validateFormInputs()}>Submit</Button>
                                <Button onClick={() => handleClick('N')} color="secondary" size="lg">Cancel</Button>
                            </div>
                        </div>
                    </ModalFooter>    
                    </>
                :
                    <ModalBody className="success-wrapper"> 
                        <div className='close-btn'><img src={`../../../assets/img/avatars/grey-cross.svg`} alt='close' onClick={() => {handleClick('X')}} className="cross"/></div>
                        <div className='success-box'>
                            <img src={`../../../assets/img/Checkmark.svg`} alt='success' className="tick"/>
                        </div>  
                        <div className='success-msg'>
                            <span>{`Thank you for submitting your feedback.`}</span>
                        </div>         
                    </ModalBody>
                }
                
                
            </Modal>
        </div>
    )
}

export default ContactUs;