//import { UserAgentApplication, Logger, LogLevel } from "msal";
import { getAppConfigs } from "../common/Utils"; 

const { clientId, tenantId } = getAppConfigs();

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE
        ]
    },
    TOKEN_REFRESH: {
        scopes: [
            clientId
        ]
    }
};
export const config = { 
    scopes: [
      'openid'
      // 'calendars.read'
    ]
  };
// export const msalApp = new UserAgentApplication({
//     auth: {
//         clientId: clientId || '8aa517bf-dc5e-41e4-a80f-0020412cd649',
//         authority: `https://login.microsoftonline.com/${tenantId || '31a2fec0-266b-4c67-b56e-2796d8f59c36'}`,
//         redirectUri: window.location.origin + "/auth.html",
//         validateAuthority: true,
//         postLogoutRedirectUri: window.location.origin,
//         navigateToLoginRequestUrl: false
//     },
//     cache: {
//         cacheLocation: "sessionStorage"
//     },
//     system: {
//         navigateFrameWait: 500,
//         logger: new Logger((logLevel, message) => {
//             console.log(message);
//         }, {
//             level: LogLevel.Verbose,
//             piiLoggingEnabled: true
//         }),
//         telemetry: {
//             applicationName: "malena",
//             applicationVersion: "1.0.0",
//             telemetryEmitter: (events) => {
//                 console.log('Telemetry Events:', events);
//             }
//         }
//     }
// });
