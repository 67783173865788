import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { Button } from "reactstrap";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import PublicFooter from "./PublicFooter";
import PublicHeader from "./PublicHeader";
import './publicLoginPre.scss';
import { downloadLoginPdfApi } from "./publicService";
import { isMobileOrTablet } from "./utils/publicUtils";
import PublicDataModal from "./utils/PublicDataModal";

const PublicLoginPre = ({ loginClick }) => {
    const [isPageSrcolled, setIsPageSrcolled] = useState(false);
    const [isMoTab, setIsMoTab] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // start of adobe analytics logic
        document.getElementById('adb-analytics') != null && document.getElementById('adb-analytics').remove();
        const script = document.createElement("script");
        script.src = "./js/AnalyticsLogin.js";
        script.id = "adb-analytics";
        script.async = true;
        document.body.appendChild(script);
        // end of adobe analytics logic

        window.scrollTo(0, 0);
        window.addEventListener('scroll', toggleScrollBtn, {passive: true});
        if (isMobileOrTablet()) {
            setIsMoTab(true);
        }
        return () => { window.removeEventListener('scroll', toggleScrollBtn) }
    }, [])

    const navigateTo = (type) => {
        if (type === 'S') {
            history.push('/public/registration');
        }
    }

    const handlePdfClick = () => {
        // download public login guide pdf from api
        const {getPdfLogin} = downloadLoginPdfApi();
        getPdfLogin().then(res => {
            if (res && res.status === 200) {
                // // open the pdf in next tab option 1 
                // let test_blob = new Blob([res.data], { type: 'application/pdf' });
                // let blobURL = window.URL.createObjectURL(test_blob);
                // window.open(blobURL,'_system'); 

                 // open the pdf in next tab option 2 
                 let a = document.getElementById("pdf_login_down");
                 a.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf;base64' }));
                 // a.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream'}));
                 // a.download = 'Malena_Registration_guide' + ".pdf";
                 a.target = '_blank';
                 a.click();
            }
        });
    }

    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const receiveModalInputs = (type) => {
        
        if (type === 'DO') {
            setIsMoTab(false);
        }
    }

    const toggleScrollBtn = ()=>{
        if(document.documentElement.scrollTop > 100 && window.innerWidth < 1025){
            setIsPageSrcolled(true);
        }else{
            setIsPageSrcolled(false);
        }
    }

    return (
        <>
            <div className="login-pre-main">
                <PublicHeader applyWhite={true} loginC={loginClick}></PublicHeader>
                <div className="login-body">
                    <div className="login-head">
                        <div className="col-12">

                            <div className="proceed">
                                <Button color="primary" onClick={() => { loginClick() }} name="login" className="signup-btn">{`Proceed to Log in`}</Button>
                                <button onClick={() => {handlePdfClick()}} className="btn ml-3 inst-pdf">{`Open Instructions in a new tab`}</button>
                                <a style={{ display: 'none' }} id="pdf_login_down"></a>
                            </div>

                        </div>
                        <div className="col-12">
                            <h1 className="main-h-1">{`Log in Instructions`}</h1>
                        </div>
                        <div className="l-footer col-12">
                            <span>{`If you do not have the Microsoft Authenticator app, follow the instructions on the `}<span onClick={() => {navigateTo('S')}} className="link">Sign up</span>{` page`}</span>
                        </div>
                    </div>
                    <div className="login-content">
                        <div className="ins-1">
                            
                            <div className="lg-wrap">
                                <div className="each-ins">
                                    <div className="text-wrap">
                                        <span className="pr-2">1.</span><p>{`Enter your email address to Log in`}</p>
                                    </div>
                                    <div className="img-wrap step-1">
                                        <span className="img-span"><img alt="login" width="225" height="290" src="../../assets/img/extPre/instructions/insLog1.svg"></img></span>
                                    </div>
                                </div>
                                <div className="next-arr">
                                    <span><img alt="arrow" width="30" height="30" src="../../assets/img/extPre/instructions/fwd-arrow.svg"></img></span>

                                </div>
                                <div className="each-ins">
                                    <div className="text-wrap">
                                        <span className="pr-2">2a.</span><p>{`A verification code will be sent to your email address if you are signing with your Email account`}</p>
                                    </div>
                                    <div className="img-wrap step-2-a">
                                        <span className="img-span"><img alt="login" width="247" height="205" src="../../assets/img/extPre/instructions/insLog2-1.svg" className="img-2a"></img></span>
                                    </div>
                                </div>
                                <div className="next-arr tab-off">
                                    <span><img alt="arrow" width="30" height="30" src="../../assets/img/extPre/instructions/fwd-arrow.svg"></img></span>

                                </div>
                                <div className="each-ins">
                                    <div className="text-wrap">
                                        <span className="pr-2">2b.</span><p>{`Enter the password associated with this email address if you are signing with your Microsoft account`}</p>
                                    </div>
                                    <div className="img-wrap step-2-b">
                                        <span className="img-span"><img alt="login" width="249" height="189" src="../../assets/img/extPre/instructions/insLog2.svg" className="img-2b"></img></span>
                                    </div>
                                </div>
                                <div className="next-arr mbl-arr">
                                    <span><img alt="arrow" width="30" height="30" src="../../assets/img/extPre/instructions/fwd-arrow.svg"></img></span>
                                </div>
                            </div>                            
                        </div>
                        
                        {/* start of 3rd type login */}
                        <div className="ins-3 pt-5">

                            {/* Start: for tab and mobile view only */}
                            <div className="tab-on">
                                <div className="two-c-step-wrap">
                                    <span className="pr-2">2c.</span><span>{`if you are signing with your Google account, Click “Next”.`}<br/>{`Enter the password associated with this email address if you are signing with your Google account`}</span>
                                </div>
                            </div>
                            {/*  End: for tab and mobile view only */}
                            
                            <div className="lg-wrap">                                
                                <div className="each-ins">
                                    <div className="text-wrap two-c-step tab-off-txt">
                                        <div className="two-c-step-wrap">
                                            <span className="pr-2">2c.</span><span>{`if you are signing with your Google account, Click “Next”.`}<br/>{`Enter the password associated with this email address if you are signing with your Google account`}</span>
                                        </div>
                                    </div>
                                    <div className="img-wrap step-2-c-1">
                                        <span className="img-span"><img alt="login" width="236" height="290" src="../../assets/img/extPre/instructions/insLog2c1.PNG"  className="img-2c-1"></img></span>
                                    </div>
                                </div>
                                <div className="next-arr">
                                    <span><img alt="arrow" width="30" height="30" src="../../assets/img/extPre/instructions/fwd-arrow.svg"></img></span>

                                </div>
                                <div className="each-ins">
                                    <div className="text-wrap">
                                        
                                    </div>
                                    <div className="img-wrap step-2-c-2">
                                        <span className="img-span"><img alt="login" width="240" height="290" src="../../assets/img/extPre/instructions/insLog2c2.PNG"  className="img-2c-2"></img></span>
                                    </div>
                                </div>
                                <div className="next-arr tab-off">
                                    <span><img alt="arrow" width="30" height="30" src="../../assets/img/extPre/instructions/fwd-arrow.svg"></img></span>

                                </div>
                                <div className="each-ins">
                                    <div className="text-wrap">
                                        <span className="pr-2">3.</span><p>{`Enter the number shown on your Microsoft Authenticator app to sign in`}</p>
                                    </div>
                                    <div className="img-wrap step-3">
                                        <span className="img-span"><img alt="login" width="197" height="297" src="../../assets/img/extPre/instructions/insLog3.svg"></img></span>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    {isPageSrcolled && 
                        <button className="d-flex btn scroll-top-btn" onClick={scrollToTop}>
                            <img width="30" height="30" src={'../../../assets/img/extPre/instructions/step3.svg'} alt="install"/>
                            <div className="btn-text"><p>Scroll to Top</p></div>
                        </button>
                    }  
                </div>
                <div className="ft-b"><PublicFooter></PublicFooter></div>
                {isMoTab ? <PublicDataModal type={'DO'} showFlag={isMoTab} sendClose={receiveModalInputs}></PublicDataModal>: ''}
            </div>

        </>
    )
}

export default withRouter(PublicLoginPre);