import React from "react";

import { withRouter } from "react-router-dom";

import PrivacyPolicy from "../../containers/TermsOfUse/PrivacyPolicy";
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import '../../containers/TermsOfUse/termsOfUse.scss';

const PublicPrivacyPolicy = ({loginClick}) => {
    return (
        <div className="ppp-main" style={{overflowX: "hidden"}}>
            <PublicHeader type="I" applyWhite={true} loginC={loginClick} ></PublicHeader>
                <div className="privacy-wrap"><PrivacyPolicy subType="P"></PrivacyPolicy></div>
            <PublicFooter></PublicFooter>
        </div>
    )
}

export default withRouter(PublicPrivacyPolicy);