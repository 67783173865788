import { getAppConfigs } from '../Utils';

const { apiURL } = getAppConfigs();

export const apiBasePath = window.location.origin.includes('localhost') ? '' : apiURL || '';
//console.log("api url", apiBasePath)
export const handleErrors = response => {
    if (!response.ok) {        
        if (response.status && response.status === 401 && (response.statusText === 'Unauthorized' || response.statusText === '')) {
            if (window.sessionTimeOutComponent) {
                window.sessionTimeOutComponent.showPopUp();
            }
        }
        throw Error(response.statusText);
    }
    return response.json();
};

export const handleErrorsTextWithClone = response => {
    if (!response.ok) {        
        if (response.status && response.status === 401 && (response.statusText === 'Unauthorized' || response.statusText === '')) {
            if (window.sessionTimeOutComponent) {
                window.sessionTimeOutComponent.showPopUp();
            }
        }
        throw Error(response.statusText);
    }
    return response.clone().text();
};

export const handleErrorsObject = response => {
    if (!response.ok) {
        if (response.status && response.status === 401 && (response.statusText === 'Unauthorized' || response.statusText === '')) {
            if (window.sessionTimeOutComponent) {
                window.sessionTimeOutComponent.showPopUp();
            }
        }
        throw Error(JSON.stringify({'status': response.status, 'statusText': response.statusText}));
    }
    return response.json();
};

export const handleErrorsForText = response => {
    if (!response.ok) {
        if (response.status && response.status === 401 && (response.statusText === 'Unauthorized' || response.statusText === '')) {
            if (window.sessionTimeOutComponent) {
                window.sessionTimeOutComponent.showPopUp();
            }
        }
        throw Error(response.statusText);
    }
    return response.text();
}

export const getAuthHeaderForText = () => {
    const token = sessionStorage.getItem('access1');
    
    //console.log("access token",token)
    return token ? {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'text'
    } : {};
}

export const getAuthHeader = () => {
    const token = sessionStorage.getItem('access1');
    
    //console.log("access token",token)
    return token ? {
        headers: {
            Authorization: `Bearer ${token}`
        }
    } : {};
}

export const getAuthHeaderPBI = () => {
    const token = sessionStorage.getItem('access_pw');
    
    //console.log("access token",token)
    return token ? {
        headers: {
            Authorization: `Bearer ${token}`
        }
    } : {};
}

export const getAuthHeaderBlob = () => {
    //const token = sessionStorage.getItem('msal.idtoken');
    const token = sessionStorage.getItem('access1');
    //console.log("token", token)
    return token ? {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'arraybuffer'
        
    } : {};
}
