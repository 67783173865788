export const validateSecurityTextPattern = (input) => {
    let pattern = /[<{^>}]/;
    return pattern.test(input);
}

export const validateEmailPattern = (input) => {
    let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !pattern.test(input);
}
export const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(window.navigator.userAgent);
}

export const isMobileOrTablet = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(window.navigator.userAgent) && window.innerWidth < 1025;
}