import React from 'react';

var axios_url =  window.appConfigs.apiURL;
var app_url_scope = window.appConfigs.audiance+"/user_impersonation";//"https://csoapi.asescdev.ifc.org/user_impersonation";//
const configAuth = {
  authority: `https://login.microsoftonline.com/${window.appConfigs.tenantId || '31a2fec0-266b-4c67-b56e-2796d8f59c36'}`,
  appId:  window.appConfigs.clientId || '8aa517bf-dc5e-41e4-a80f-0020412cd649',  
  redirectUri:  window.appConfigs.homepage,
  postLogoutRedirectUri: window.location.origin,
  scopes: [
    app_url_scope,
    //'https://csoapi.asescdev.ifc.org/user_impersonation'    
    // 'calendars.read'
    
  ],forceRefresh: true
};
const powerbiAuth = {
     authority: 'https://login.microsoftonline.com/worldbank.org',
     appId: window.appConfigs.clientId || '8aa517bf-dc5e-41e4-a80f-0020412cd649', 
     redirectUri: window.appConfigs.homepage, //e.g.//'https://powerbiembeddeddev.ifc.org/',
    
    //authority: window.config_authority,
    //appId:  window.config_appId,
    //redirectUri:  window.config_redirectUri,
    scopes: [   
       "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
       "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
       "https://analysis.windows.net/powerbi/api/Dataset.ReadWrite.All",   
      "https://analysis.windows.net/powerbi/api/Report.Read.All",   
       "https://analysis.windows.net/powerbi/api/Content.Create",
    ], forceRefresh: false
  };
export {   
  configAuth, axios_url, powerbiAuth
  };