import { useState } from "react";
import { createContext } from "react";

export const DataContext = createContext();

const DataProvider = ({children}) =>{
    const [dataObj, setDataObj] = useState(null);
    const [hasZeroProjects, setHasZeroProjects] = useState(null);

    const updateObj = (newObj) =>{
        setDataObj(newObj);
    }

    const updateZeroProjects = (val) => {
        setHasZeroProjects(val ? true : false);
    }

    return(
        <DataContext.Provider value={{dataObj, updateObj, hasZeroProjects, updateZeroProjects}}>
            {children}
        </DataContext.Provider>
    )
}

export default DataProvider;