import { apiBasePath } from "../../common/APIUtils";
import axios from "axios";

export const getPublicVideoApi = () => {   
    return axios.get(`${apiBasePath}/malena/video`, {
        // body: JSON.stringify(queryObj),        
        responseType: 'arraybuffer'                    
    });
}

export const getRegistrationLimitApi = () => {
    return fetch(`${apiBasePath}/malena/welcome`,
        {
            headers: {
                'Content-type': 'application/json'
            }
        }
    ).then(handleErrObjText);
}

export const sendWaitlistEmailApi = (payload) => {
    return fetch(`${apiBasePath}/malena/waitlist`,
        {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-type': 'application/json'
            }
        }).then(handleErrObjText);
        
}

export const downloadRegPdfApi = () => {
    return {
        getPdfReg: () => axios.get(`${apiBasePath}/Malena/getSignupInstructions`, {
            // body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer'                    
        })
    };
}

export const downloadLoginPdfApi = () => {
    return {
        getPdfLogin: () => axios.get(`${apiBasePath}/Malena/getLoginInstructions`, {
            // body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer'                    
        })
    };
}

const handleErrObjText = response => {
    if (!response.ok) {
        
        throw Error(JSON.stringify({'status': response.status, 'statusText': response.statusText}));
    }
    return response.text();
};

export const getFaq = ()=>{
    return fetch(`${apiBasePath}/faq`,
        {
            headers: {'Content-type': 'application/json'}
        }
    ).then(handleErrObjText);
}

export const sendContatUsApi = (payload) => {
    return fetch(`${apiBasePath}/malena/contactus`,
        {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-type': 'application/json'
            }
        }).then(handleErrObjText);
        
}