export const mockSentenceAiData = {
    'transformerbasedmodeldistilbert': {
      "modelType": "Transformer-based model (DistilBERT)",
      "sentence": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid.",
      "modelPurpose": "The model is trained to identify 1,120 ESG risk terms in the text and classify their ESG sentiment as positive, negative, or neutral, based on the context of the sentence they appear in. ",
      "predictionTask": "ESG sentiment analysis",
      "trainingData": "This model is trained through examples from a training data set of 107,541 manual annotations labeled by IFC’s ESG analysts. The training set comprises 31,482 positive labels, 23,459 negative labels, and 52,600 neutral labels",
      "testingData": "Model evaluation and validation are conducted using a random selection of 18,940 additional manual annotations. Model performance is evaluated using weighted F1 scores and accuracy scores. The F1 score is 86.7%, and the accuracy score is 86.9%.",
      "evaluationDate": "2022-08-21T22:24:38+00:00",
      "id": "transformerbasedmodeldistilbert",
      "sentence_id": [
        "sen_1",
        "sen_2",
        "sen_3",
        "sen_4",
        "sen_5",
        "sen_6",
        "sen_7"
      ],
      "widgets": [
        "Explainability",
        "Confidence"
      ],
      "radarChartData": {
        "labels": [
          "Predictive Performance",
          "Confidence",
          "Fairness",
          "Explainability",
          "Adversarial Robustness",
          "Drift"
        ],
        "selectedModel": [
          86.9,
          94.74,
          69.5,
          80,
          77.47,
          88.5
        ],
        "benchMarkModel": [
          83,
          85,
          60,
          70,
          70,
          85
        ]
      },
      "progressChartData": [
        {
          "label": "Predictive Performance",
          "description": "Measures the ability to correctly predict sentiments",
          "selectedScore": 86.9,
          "benchMarkScore": 83
        },
        {
          "label": "Confidence",
          "description": "Measures the certainty of the model’s prediction",
          "selectedScore": 94.74,
          "benchMarkScore": 85
        },
        {
          "label": "Fairness",
          "description": "Measures the model’s ability to identify and mitigate bias before performing sentiment analysis",
          "selectedScore": 69.5,
          "benchMarkScore": 60
        },
        {
          "label": "Explainability",
          "description": "Measures the model's ability to explain the reasoning behind sentiment prediction",
          "selectedScore": 80,
          "benchMarkScore": 70
        },
        {
          "label": "Adversarial Robustness",
          "description": "Measures the model's flexibility in making correct predictions given small variations in the input",
          "selectedScore": 77.47,
          "benchMarkScore": 70
        },
        {
          "label": "Drift",
          "description": "Measures the model’s ability to resist the decay of predictive performance as a result of the changes in real-world environments.",
          "selectedScore": 88.5,
          "benchMarkScore": 85
        }
      ]
    }
    ,
'transformerbasedmodel': {
  "modelType": "Transformer-based model  (RoBERTa)",
  "sentence": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid.",
  "modelPurpose": "The model is trained to identify 1,120 ESG risk terms in the text and classify their ESG sentiment as positive, negative, or neutral, based on the context of the sentence they appear in.",
  "predictionTask": "ESG sentiment analysis",
  "trainingData": "This model is trained through examples from a training data set of 107,541 manual annotations labeled by IFC's ESG analysts. The training set comprises 31,482 positive labels, 23,459 negative labels, and 52,600 neutral labels.",
  "testingData": "Model evaluation and validation are conducted using a random selection of 18,940 additional manual annotations. Model performance is evaluated using weighted F1 scores and accuracy scores. The F1 score is 90.1%, and the accuracy score is 90.2%.",
  "evaluationDate": "2022-05-22T22:24:38+00:00",
  "id": "transformerbasedmodel",
  "sentence_id": [
    "sen_1",
    "sen_2",
    "sen_3",
    "sen_4",
    "sen_5",
    "sen_6",
    "sen_7"
  ],
  "widgets": [
    "Explainability",
    "Confidence"
  ],
  "radarChartData": {
    "labels": [
      "Predictive Performance",
      "Confidence",
      "Fairness",
      "Explainability",
      "Adversarial Robustness",
      "Drift"
    ],
    "selectedModel": [
      90.2,
      96.15,
      69.5,
      80,
      73.64,
      88.5
    ],
    "benchMarkModel": [
      83,
      85,
      60,
      70,
      70,
      85
    ]
  },
  "progressChartData": [
    {
      "label": "Predictive Performance",
      "description": "Measures the ability to correctly predict sentiments",
      "selectedScore": 90.2,
      "benchMarkScore": 83
    },
    {
      "label": "Confidence",
      "description": "Measures the certainty of the model’s prediction",
      "selectedScore": 96.15,
      "benchMarkScore": 85
    },
    {
      "label": "Fairness",
      "description": "Measures the model’s ability to identify and mitigate bias before performing sentiment analysis",
      "selectedScore": 69.5,
      "benchMarkScore": 60
    },
    {
      "label": "Explainability",
      "description": "Measures the model's ability to explain the reasoning behind sentiment prediction",
      "selectedScore": 80,
      "benchMarkScore": 70
    },
    {
      "label": "Adversarial Robustness",
      "description": "Measures the model's flexibility in making correct predictions given small variations in the input",
      "selectedScore": 73.64,
      "benchMarkScore": 70
    },
    {
      "label": "Drift",
      "description": "Measures the model’s ability to resist the decay of predictive performance as a result of the changes in real-world environments.",
      "selectedScore": 88.5,
      "benchMarkScore": 85
    }
  ],
  "aboutProgressChartData": [
    {
      "label": "Model Performance",
      "id": "MP",
      "selectedScore": 91.30,
      "benchMarkScore": 83
    },
    {
      "label": "Fairness",
      "id": "FA",
      "selectedScore": 71.50,
      "benchMarkScore": 60
    },
    {
      "label": "Explainability",
      "id": "EX",
      "selectedScore": 80.13,
      "benchMarkScore": 70
    },
    {
      "label": "Confidence",
      "id": "CO",
      "selectedScore": 96.15,
      "benchMarkScore": 85
    },
    {
      "label": "Adversarial Robustness",
      "id": "AR",
      "selectedScore": 73.64,
      "benchMarkScore": 70
    },
    {
      "label": "Model Drift",
      "id": "MD",
      "selectedScore": 88.50,
      "benchMarkScore": 85
    }
  ]
}
}