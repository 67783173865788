import React, { useEffect, useState } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";

import PublicFooter from "./PublicFooter";
import PublicHeader from "./PublicHeader";
import ProgressCharts from "./ProgressCharts";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { dataSecurityFaq } from "../../mock/publicDataSecurityFaq";

import './publicAboutMalena.scss';

const PublicAboutMalena = ({ isLoggedIn }) => {
    const DEFAULT_ACTIVE_TAB = 'MTDS';
    const ALL_TAB_NAMES = ['MTDS', 'RAI', 'MIO'];

    const [isParameter, setIsParameter] = useState(false);
    const [activeParam, setActiveParam] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [faq, setFaq] = useState(dataSecurityFaq);

    const history = useHistory();
    const location = useLocation();


    useEffect(() => {
        if (window.location && window.location.pathname) {
            window.scrollTo(0, 0);
        }

        // adobe analytics logic
        document.getElementById('adb-analytics') != null && document.getElementById('adb-analytics').remove();
        const script = document.createElement("script");
        script.src = "./js/AnalyticsAbout.js";
        script.id = "adb-analytics";
        script.async = true;
        document.body.appendChild(script);
        return function cleanup() {
            sessionStorage.setItem('redirectTourAbout', false)
        };
    }, []);

    useEffect(() => {
        
            ALL_TAB_NAMES.push('MFAQ');
        
        if (location && location.search) {
            const searchStr = location.search;
            let method1 = new URLSearchParams(searchStr);
            let name = method1.get('tab');
            if (name && activeParam === name) {

            } else if (name && ALL_TAB_NAMES.includes(name)) {
                setIsParameter(true);
                setActiveParam(name);
            } else {
                setIsParameter(false);
            }
            window.scrollTo(0, 0);
        } else {
            setIsParameter(false);
            setActiveParam(null);
            window.scrollTo(0, 0);
        }
    }, [location]);

    const next = () => {
        setCurrentSlide(currentSlide + 1);
    };

    const prev = () => {
        setCurrentSlide(currentSlide - 1);
    };

    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index);
        }
    };

    const handleCardClick = (val) => {
        // setIsParameter(true);
        // setActiveParam(val);
        history.push({
            pathname: `${isLoggedIn ? '/about-malena' : '/public/about-malena'}`,
            search: `?tab=${val}`
        });
    }

    const handleBreadClick = (val) => {
        if (val === 'H') {
            history.push({
                pathname: `${isLoggedIn ? '/about-malena' : '/public/about-malena'}`
            });
            // setIsParameter(false);
            setActiveParam(null);
        }
    }

    const openNewWindow = (val) => {
        if (val) {
          window.open(`${window.location.origin}/#/${val}`);
        }    
    }
    const openAns = (val) => {
        let dupFaq = [...faq];
        if(val === 'open'){
            dupFaq.map(f=>{
                f.isOpen = true;
            })
        }else if(val === 'close'){
            dupFaq.map(f=>{
                f.isOpen = false;
            })
        }else{
            dupFaq.map((f,ind)=>{
                if(ind === val){
                    f.isOpen = !f.isOpen;
                }
            })
        }
        setFaq(dupFaq);
    }

    return (
        <div className={`abt-malena-main ${isLoggedIn ? '' : 'public'}`}>
            {isLoggedIn ? '' :
                <PublicHeader applyWhite={true} activePageName={'A'}></PublicHeader>
            }
            {isParameter ?
                // START of left hand side nav tabs 
                <div className="abt-card-body">
                    <div className="abt-bc">
                        <span className="link" onClick={() => { handleBreadClick('H') }}>{`${isLoggedIn ? 'About Us' : 'About Malena'}`}</span>
                        <span className="pl-2 pr-2"><i className="fa fa-angle-left"></i></span>
                        <span>{`View Details`}</span>
                    </div>
                    <div className="hr-line"></div>
                    <div className="all-t-wrapper">
                        <Tab.Container id="left-tabs"
                            defaultActiveKey={DEFAULT_ACTIVE_TAB}
                            activeKey={activeParam}
                            onSelect={(key) => handleCardClick(key)}>
                            <div className={`t-names-nav ${isLoggedIn ? 'int-names-nav': 'ext-names-nav'}`}>
                                <Nav variant="pills" className="">                                    
                                    <Nav.Item className={`abt-tabs-ext small`}>
                                        <Nav.Link eventKey={'MTDS'}><span>{`Model Training Data Summary`}</span><span className="triangle"></span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={`abt-tabs-ext rai-2`}>
                                        <Nav.Link eventKey={'RAI'}><span>{`Responsible AI`}</span><span className="triangle"></span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={`abt-tabs-ext}`}>
                                        <Nav.Link eventKey={'MIO'}><span>{`Input and Output`}</span><span className="triangle"></span></Nav.Link>
                                    </Nav.Item>
                                    
                                    <Nav.Item className={`abt-tabs-ext small`}>
                                        <Nav.Link eventKey={'MFAQ'}><span>{`Data Security FAQs`}</span><span className="triangle"></span></Nav.Link>
                                    </Nav.Item>
                                    
                                </Nav>
                            </div>
                            <div className="t-content">
                                <Tab.Content>                                    
                                    <Tab.Pane eventKey="MTDS">
                                        <div className="each-content mtds">
                                            <div className="only-p">
                                                <div className="each-t-main-title mb-3">
                                                    <h3 className="main-h-3">{`Model Training Data Summary`}</h3>
                                                </div>
                                                <div className="each-t-content">
                                                    <p className="pb-4">{`MALENA is a supervised large language model trained using examples from a dataset of over 150,000 manual annotations. Annotation guidelines and inter-annotator agreements are maintained to ensure high-quality training data.`}</p>
                                                    <p>{`MALENA’s performance is evaluated using 25,000 randomly selected labels from annotations, unseen during the model training phase. With 91% accuracy in ESG sentiment analysis, MALENA outperforms out-of-the-box sentiment analysis models with lower accuracy levels of 68%.`}</p>
                                                </div>
                                            </div>
                                            <div className="ic"><img src="../../assets/img/extPre/aboutMalena/mtds_icon.svg" alt="" width="344" height="337"></img></div>
                                        </div>
                                    </Tab.Pane>
                                    {/* START of Respinsible AI tab content */}
                                    <Tab.Pane eventKey="RAI">
                                        <div className="each-content rai">
                                            <div className="each-t-main-title">
                                                <h3 className="main-h-3">{`Responsible AI Framework for MALENA`}</h3>
                                            </div>
                                            <div className="each-t-content">
                                                <div className="pb-2">                                                    
                                                    <div className="each-t-para">
                                                        <p>{`Complex AI systems, like large language models based on neural networks, risk becoming black boxes when it is difficult to understand how results are generated. To trust the output of machine learning (ML) algorithms, it is imperative for individuals and organizations to know that the AI in use is fair, reliable, unbiased, explainable, and will not cause harm. By embedding ethical principles into the development and deployment of AI applications and processes, developers can create ML systems based on trust.`}</p>
                                                    </div>
                                                    <div className="each-t-para">
                                                        <p>{`IFC seeks to apply a responsible approach to developing and deploying MALENA based on the principles of trustworthiness and explainability. IFC identified best practices for governing MALENA data and models through a literature review and review of responsible AI frameworks.`}</p>
                                                    </div>
                                                    <div className="each-t-para">
                                                        <p>{`For MALENA, the approach entails the development of grounding features that allow users to trace output back to the source documents, a model evaluation dashboard covering six metrics, and a machine learning operations process to ensure the traceability and auditability of MALENA.`}</p>
                                                    </div>
                                                </div>
                                                <div className="pb-2">
                                                    <div className="each-t-title">
                                                        <span><img src="../../assets/img/extPre/aboutMalena/gro_fea.svg" alt="grounding-features" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`Grounding features`}</span>
                                                    </div>
                                                    <div className="each-t-para">
                                                        <p>{`Trustworthiness in the MALENA is enabled through implementation of a grounding feature within the MALENA user interface that allows users to trace model predictions back to the source document. This provides complete transparency back to the input data.`}</p>
                                                    </div>                                                    
                                                </div>
                                                <div className="pb-2">
                                                    <div className="each-t-title">
                                                        <span><img src="../../assets/img/extPre/aboutMalena/mod_eva.svg" alt="model dashboard" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`Model Evaluation Dashboard`}</span>
                                                    </div>
                                                    <div className="each-t-para">
                                                        <p>{`- MALENA is evaluated in six categories: Performance, Confidence, Fairness, Explainability, Adversarial Robustness, and Drift. As MALENA does not use any personally identifiable information (“PII”) for training and inference purposes, the model is not evaluated on Privacy.`}</p>
                                                    </div>  
                                                    <div className="pr-charts-row">
                                                        <ProgressCharts></ProgressCharts>
                                                    </div>                                                  
                                                </div>  
                                                <div className="pb-2">
                                                    <div className="each-t-title">
                                                        <span><img src="../../assets/img/extPre/aboutMalena/mlops.svg" alt="mlops" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`Machine Learning Operations`}</span>
                                                    </div>
                                                    <div className="each-t-para">
                                                        <p>{`The MALENA machine learning operations (“MLOps”) process supports traceability and auditability. Model metadata and artifacts, including training data, experiment details, and model performance metrics are stored and governed within a model registry.`}</p>
                                                    </div>                                                    
                                                </div>                                             
                                               
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    {/* END of Respinsible AI tab content */}
                                    <Tab.Pane eventKey="MIO">
                                        <div className="each-content mio">
                                            <div className="each-t-main-title">
                                                <h3 className="main-h-3">{`Input and Output`}</h3>
                                            </div>
                                            <div className="each-t-content">
                                                <div className="inp-s">
                                                    <div className="title">
                                                        <span><img alt="input" src="../../assets/img/extPre/aboutMalena/m_inp.svg" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`Input`}</span>
                                                    </div>
                                                    
                                                        <>
                                                            <p>{`MALENA is an “Artificial Intelligence (AI) as a Service” solution that analyzes data provided by users. Users upload documents that are owned by or authorized for use by them, for analysis by MALENA. MALENA can read ESG-relevant text such as sustainability reports, environmental and social impact assessments, and news articles.`}</p>
                                                            <p>{`Documents can be uploaded as PDF, Word, or raw text files. At present MALENA can only provide sentiment analysis for documents in English. Uploads are limited to a maximum of 40 MB per document. Users can upload up to 20 documents or a total of 100 MB at one time, whichever is lower. Each user has a maximum storage space of 1 GB for all uploads.`}</p>
                                                        </>
                                                                                                      
                                                </div>
                                                <div className="out-s pt-3">
                                                    <div className="title">
                                                        <span><img alt="output" src="../../assets/img/extPre/aboutMalena/m_out.svg" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`Output`}</span>
                                                    </div>
                                                    <p>{`MALENA identifies over 1,000 ESG risk terms in text and assigns positive, negative, or neutral sentiment based on the context in which these terms are found in a sentence. This information is organized by `}<a href="https://www.ifc.org/en/insights-reports/2012/ifc-performance-standards" target="_blank" rel="noopener noreferrer" className="link">IFC’s Environmental and Social Performance Standards</a>{` and `}<a href="https://www.ifc.org/en/what-we-do/sector-expertise/corporate-governance/cg-methodology-tools" target="_blank" rel="noopener noreferrer" className="link">Corporate Governance Methodology</a>{`. Positive sentiments are highlighted in green, negative sentiments in red, and neutral sentiments in yellow.`}</p>
                                                    <p>{`For example,`}</p>
                                                    <div className="highlight">
                                                        <span>
                                                            <p>The <span className="neg">floods</span> that occurred had a terrible impact on lives and <span className="neg">livelihoods</span>, but what may be of greater concern is the likelihood of its recurrence. Experts have warned that there will be an increase in the number of <span className="neg">climate</span> catastrophes as a result of <span className="neg">climate</span> change, and growing number of <span className="neg">climate</span> disasters in recent years is proof positive of that.
                                                            The world was reminded of the urgency of the situation at the <span className="neu">Climate Change</span> Conference. At the end of the conference, countries signed a <span className="pos">climate</span> change pact that promised accelerated action to reduce <span className="pos">greenhouse gas (GHG) emissions</span>.</p>
                                                        </span>
                                                    </div>
                                                    <p>{`MALENA’s analysis is organized in an interactive dashboard also allowing users to link back to their source documents.`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="MFAQ">
                                        <div className="each-content mfaq">
                                            <div className="each-t-main-title">
                                                <h3 className="main-h-3 mb-4">{`Data Security FAQs`}</h3>
                                                <div className="d-flex justify-content-between align-items-center pb-2">
                                                    <span className="date-title">{`January 2024`}</span>
                                                    <div className="d-flex">
                                                        <div className="pr-3"><img alt="input" src="../../assets/img/extPre/aboutMalena/faqClosed.svg" width="20" height="20"/><span className="tr-links pl-2" onClick={()=>openAns('open')}>Expand All</span></div>
                                                        <div><img alt="input" src="../../assets/img/extPre/aboutMalena/faqOpened.svg" width="20" height="20"/><span className="tr-links pl-2" onClick={()=>openAns('close')}>Collapse All</span></div>
                                                    </div>
                                                </div>
                                                <span>{`(To be available to all users of MALENA (“user”, “you”) accessing the external website)`}</span>
                                            </div>
                                            <div className="each-t-content mt-3">
                                                <div className="inp-s">
                                                    <p className="line">{`These FAQs are not intended to create any legal relationship or contract between IFC and you (the user) and do not form part of any terms of use in relation to MALENA.  These FAQs do not constitute an offer by IFC capable of becoming a contract, nor are these FAQs legally binding terms on either the user or IFC.  These FAQs do not constitute a waiver, termination, or modification by IFC of any privilege, immunity, or exemption of IFC granted in the Articles of Agreement establishing IFC, international conventions, or applicable law.`}</p>
                                                    <br/>
                                                    {/* Ques 1 */}
                                                    <div className='line'>
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`1.`}</span><span className="pr-2">{faq[0].ques}</span></strong><img src={faq[0].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(0)}/></p>
                                                        {faq[0].isOpen &&
                                                        <>
                                                            <p>{`The MALENA ESG Sentiment Analysis model and any related MALENA product offering made available via web-based interfaces or any other method (collectively, “MALENA” or the “Site”) is a public good offering of the International Finance Corporation (“IFC”). MALENA is a cloud-native application, built on top of IFC and other World Bank Group Institutions’  (“WBG”) cloud infrastructure, which relies on third party service providers.  MALENA is built based on existing WBG institutional practices.`}</p>
                                                            <p>{`As a general practice, user data for MALENA is hosted in the United States. The choice of cloud infrastructure is governed by WBG Procurement Policies and Procedures including the mandatory corporate use of pre-selected cloud service providers.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="inp-s">
                                                    <div className="title">
                                                        <span><img alt="input" src="../../assets/img/extPre/aboutMalena/m_inp.svg" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`INPUTS – USER DATA:`}</span>
                                                    </div>
                                                    
                                                    {/* Ques 2 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`2.`}</span><span className="pr-2">{faq[1].ques}</span></strong><img src={faq[1].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(1)}/></p>
                                                        {faq[1].isOpen &&
                                                        <>
                                                            <p>{`MALENA is a “bring your own data” service and can only access data, information and other materials that you – the user - uploads to it.   Please note that MALENA may not be able to access, analyze, or produce outputs from data that is not in an appropriate format.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 3 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`3.`}</span><span className="pr-2">{faq[2].ques}</span></strong><img src={faq[2].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(2)}/></p>
                                                        {faq[2].isOpen &&
                                                        <>
                                                            <p>{`MALENA follows WBG data retention practices, which implement technical retention capabilities consistent with the WBG’s mandate and function as an international development financial institution.`}</p>
                                                            <p>{`In line with those practices, the following data is stored:`}
                                                                <ul>
                                                                    <li className="d-flex"><span className="pr-2">{`-`}</span><p>{`Login information: Username, email address and other login information (if any) provided by a user, stored to enable a user’s access to MALENA for as long as a user’s account is active.`}</p></li>
                                                                    <li className="d-flex"><span className="pr-2">{`-`}</span><p>{`User input data: Documents, data, information, and other materials uploaded by a user to MALENA are retained for six months, after which period all such data is permanently deleted unless the user requests IFC to retain this information for a further six months, after which it will be deleted. If users elect to delete their MALENA accounts, all stored data will be permanently deleted.`}</p></li>
                                                                    <li className="d-flex"><span className="pr-2">{`-`}</span><p>{`Feedback: This is separate to other user data that a user inputs into MALENA and will be collected from a user through a separate part of the MALENA site or through other forms of communication with IFC. IFC will own this and may use and store it at its discretion.`}</p></li>
                                                                </ul>
                                                            </p>
                                                            <p>{`MALENA accounts inactive for one year (12 months) are deleted.`}</p>
                                                            <p>{`Further details about deletion of accounts, retention of data and ownership and use of Feedback by IFC are set out in the MALENA Terms of Use, available`} <span onClick={() => {openNewWindow(`${isLoggedIn ? "static/terms-conditions": "public/terms-conditions"}`)}} className="tr-links">here</span>{`. The MALENA Terms of Use are separate to these FAQs, and (unlike these FAQs, which are non-binding and not enforceable against IFC) form a part of the contractual agreement you sign up to when you access and use MALENA.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="inp-s">
                                                    <div className="title">
                                                        <span><img alt="output" src="../../assets/img/extPre/aboutMalena/m_out.svg" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`OUTPUTS:`}</span>
                                                    </div>
                                                    {/* Ques 4 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`4.`}</span><span className="pr-2">{faq[3].ques}</span></strong><img src={faq[3].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(3)}/></p>
                                                        {faq[3].isOpen &&
                                                        <>
                                                            <p>{`MALENA is an “Artificial Intelligence (AI) as a Service” solution that analyzes data provided by you – the users. A user can upload into MALENA documents, information, data and other materials that are in the appropriate format, and which in each case are owned, or authorized for use, by the user (“Input Data”). MALENA will analyze this Input Data and produce results – outputs - which users can export from the MALENA user interface. For more details on the limitations of the results please see the MALENA Terms of Use, available here. The MALENA Terms of Use are separate to these FAQs, and (unlike these FAQs, which are non-binding and not enforceable against IFC) form a part of the contractual agreement you sign up to when you access and use MALENA.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="inp-s">
                                                    <div className="title">
                                                        <span><img alt="output" src="../../assets/img/extPre/aboutMalena/m_security.svg" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`SECURITY, BACK-UPS AND COMPLIANCE IN RESPECT OF USER INPUT DATA (NOT INCLUDING FEEDBACK):`}</span>
                                                    </div>
                                                    {/* Ques 5 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`5.`}</span><span className="pr-2">{faq[4].ques}</span></strong><img src={faq[4].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(4)}/></p>
                                                        {faq[4].isOpen &&
                                                        <>
                                                            <p>{`WBG implements mature industry-recognized practices related to identity, credentials and access management (“ICAM”) including, among other capabilities, user access rules based on the need to know. Credentials/entitlements are secured/validated and authentication mechanisms are commensurate with assessed risk.`}</p>
                                                            <p>{`The exchange of data between users and MALENA is set out to be carried via encrypted Internet connections. The encryption is done via Transport Layer Security (“TLS”) and in accordance with WBG data encryption practices, which require that:`}
                                                                <ul>
                                                                    <li className="d-flex"><span className="pr-2">{`-`}</span><p>{`data encryption is enabled in storage in the cloud infrastructure, and`}</p></li>
                                                                    <li className="d-flex"><span className="pr-2">{`-`}</span><p>{`data at rest within MALENA is encrypted using industry-leading encryption standards such as Advanced Encryption Standards (“AES”), and `}</p></li>
                                                                    <li className="d-flex"><span className="pr-2">{`-`}</span><p>{`data backups are encrypted.`}</p></li>
                                                                </ul>
                                                            </p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 6 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`6.`}</span><span className="pr-2">{faq[5].ques}</span></strong><img src={faq[5].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(5)}/></p>
                                                        {faq[5].isOpen &&
                                                        <>
                                                            <p>{`MALENA uses logical isolation based on roles to segregate each user’s data from the data of others. Additionally, encrypting data at rest and in transit further enhances data isolation.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 7 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`7.`}</span><span className="pr-2">{faq[6].ques}</span></strong><img src={faq[6].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(6)}/></p>
                                                        {faq[6].isOpen &&
                                                        <>
                                                            <p>{`Data is backed up according to the WBG’s established backup schedules and leveraging industry-recognized backup tools and capabilities. Backups are set out to be periodically tested, commensurate with business requirements.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 8 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`8.`}</span><span className="pr-2">{faq[7].ques}</span></strong><img src={faq[7].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(7)}/></p>
                                                        {faq[7].isOpen &&
                                                        <>
                                                            <p>{`There are no service guarantees or audits of input and output data. Please see the MALENA Terms of Use, available`} <span onClick={() => {openNewWindow(`${isLoggedIn ? "static/terms-conditions": "public/terms-conditions"}`)}} className="tr-links">here</span>{` for more details. The MALENA Terms of Use are separate to these FAQs, and (unlike these FAQs, which are non-binding and not enforceable against IFC) form a part of the contractual agreement you sign up to when you access and use MALENA.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 9 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`9.`}</span><span className="pr-2">{faq[8].ques}</span></strong><img src={faq[8].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(8)}/></p>
                                                        {faq[8].isOpen &&
                                                        <>
                                                            <p>{`There is no guarantee regarding the capability to recover data in the case of a failure or data loss. Please see the MALENA Terms of Use, available`} <span onClick={() => {openNewWindow(`${isLoggedIn ? "static/terms-conditions": "public/terms-conditions"}`)}} className="tr-links">here</span>{` for more details. The MALENA Terms of Use are separate to these FAQs, and (unlike these FAQs, which are non-binding and not enforceable against IFC) form a part of the contractual agreement you sign up to when you access and use MALENA.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 10 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`10.`}</span><span className="pr-2">{faq[9].ques}</span></strong><img src={faq[9].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(9)}/></p>
                                                        {faq[9].isOpen &&
                                                        <>
                                                            <p>{`As an IFC application, MALENA is built on top of the WBG’s cloud infrastructure and relies on institutional security controls and systems, including identifying and managing vulnerabilities.`}</p>
                                                            <p>{`The WBG has a comprehensive set of cybersecurity policies and procedures which are periodically reviewed as well as a mature cybersecurity risk management program. The WBG deploys a multi-layered approach in its cybersecurity risk management practice which is consistent with industry-recognized standards and frameworks to help detect and prevent malicious activity, both from within the organization and from external sources.  The practices include leveraging industry-recognized intrusion prevention and detection capabilities, policy-driven security and security baselines, systems hardening protocols, source code analysis, control and secure deployment practices, debugging and monitoring, vulnerability management, mobile device management, and security logging and monitoring capabilities.`}</p>
                                                            <p>{`The WBG periodically assesses the maturity and effectiveness of its cyber defenses and business continuity plans through risk mitigation techniques.  Techniques used include, but are not limited to, targeted testing, internal and external audits, incident response and business continuity tabletop exercises, and industry benchmarking, with commensurate follow-up, optimization, and remediation, as appropriate. This means the measures outlined above may change from time to time. This occurs both at planned intervals and in response to environmental and legal/regulatory factors.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 11 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`11.`}</span><span className="pr-2">{faq[10].ques}</span></strong><img src={faq[10].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(10)}/></p>
                                                        {faq[10].isOpen &&
                                                        <>
                                                            <p>{`As an IFC application, MALENA is built on top of the WBG’s cloud infrastructure and relies on institutional BCP/DRP. The WBG follows industry-recognized business continuity and disaster recovery practices, including implementing and periodically testing BC/DR capabilities, as appropriate.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="inp-s">
                                                    <div className="title">
                                                        <span><img alt="output" src="../../assets/img/extPre/aboutMalena/m_miscel.svg" width="35" height="35"></img></span>
                                                        <span className="pl-2">{`MISCELLANEOUS QUESTIONS:`}</span>
                                                    </div>
                                                    {/* Ques 12 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`12.`}</span><span className="pr-2">{faq[11].ques}</span></strong><img src={faq[11].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(11)}/></p>
                                                        {faq[11].isOpen &&
                                                        <>
                                                            <p>{`No.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 13 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`13.`}</span><span className="pr-2">{faq[12].ques}</span></strong><img src={faq[12].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(12)}/></p>
                                                        {faq[12].isOpen &&
                                                        <>
                                                            <p>{`As an IFC application, MALENA is being developed with the support of strategic WBG selected vendors. MALENA follows WBG Procurement Policies and Procedures including the mandatory use of pre-selected third-party vendors for substantial/key tasks.`}</p>
                                                            <p>{`WBG follows industry-recognized vendor risk management practices. External service providers and contractors are subject to contractual obligations related to cybersecurity, business continuity and data privacy, and they are informed regarding applicable WBG policies and procedures that need to be followed, including data incident notification requirements/protocols where appropriate. External service provider engagements are risk-assessed, monitored for performance, and periodically reviewed for compliance with contractual obligations subject to and based on the risk profile.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 14 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`14.`}</span><span className="pr-2">{faq[13].ques}</span></strong><img src={faq[13].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(13)}/></p>
                                                        {faq[13].isOpen &&
                                                        <>
                                                            <p>{`No. These FAQs are not intended to create any legal relationship or contract between IFC and you (the user), and do not form part of any terms of use in relation to MALENA.  These FAQs do not constitute an offer by IFC capable of becoming a contract, nor are these FAQs legally binding terms on IFC.  These FAQs do not constitute a waiver, termination, or modification by IFC of any privilege, immunity, or exemption of IFC granted in the Articles of Agreement establishing IFC, international conventions, or applicable law.`}</p>
                                                            <p>The MALENA Terms of Use, the <strong><span onClick={() => {openNewWindow(`${isLoggedIn ? "static/privacy-policy": "public/privacy-policy"}`)}} className="tr-links">Privacy Notice</span></strong> and/or the <strong><span onClick={() => {openNewWindow(`${isLoggedIn ? "static/copyright-statement":"public/copyright-statement"}`)}} className="tr-links">Copyright and Disclaimer Notice</span></strong>, are separate to these FAQs, and (unlike these FAQs) form a part of the contractual agreement you sign up to when you access and use MALENA.</p>
                                                        </>
                                                        }
                                                    </div>
                                                    <br/>
                                                    {/* Ques 15 */}
                                                    <div className="line">
                                                        <p className="d-flex justify-content-between"><strong className="d-flex"><span className="pr-2 pl-1">{`15.`}</span><span className="pr-2">{faq[14].ques}</span></strong><img src={faq[14].isOpen ? '../../assets/img/extPre/aboutMalena/faqOpened.svg' : '../../assets/img/extPre/aboutMalena/faqClosed.svg'} alt="plus-minus" width="30" height="30" className="faq-img" onClick={()=>openAns(14)}/></p>
                                                        {faq[14].isOpen &&
                                                        <>
                                                            <p>{`Inquiries and notifications related to information security should be sent via email to the Office of Information Security at`} <a data-text="malena_infosec_email_link" data-customlink="em: about malena infosec email link" href={`mailto: infosec@worldbank.org`} className="tr-links">{`infosec@worldbank.org`}</a>{` referencing MALENA in the subject line.`}</p>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
                // END of left hand side nav tabs
                :
                // START of about malena home page
                <div className="abt-mal-body">
                    <div className="mal-glance">
                        <div className="glance-text row">
                            <div className="col-12">
                                <h2 className="head main-h-2">{`MALENA at a glance`}</h2>
                                <div className="para">
                                    <h3 className="sub-h-3">{`Trained by IFC on unique emerging markets ESG data, MALENA uses natural language processing to analyze unstructured text such as annual and sustainability reports, news, and impact assessments, and rapidly identifies over 1,000 ESG risk terms and predicts sentiments based on context.​`}</h3>
                                </div>
                            </div>

                        </div>
                        <div className="glance-img" style={{ background: `url('../../../assets/img/extPre/aboutMalena/abt-mal-wave.svg') no-repeat 100%` }}>
                        </div>
                        <div className="glance-img-2" style={{ background: `url('../../../assets/img/extPre/aboutMalena/tab-wave.svg') no-repeat 100%` }}>
                        </div>
                        <div className="glance-lap-img" style={{ background: `url('../../../assets/img/extPre/aboutMalena/abt-mal-laptop.svg') no-repeat 100%` }}></div>
                    </div>
                    {/* esg analysis info */}
                    <div className="esg-analysis row">
                        <div className="ml-a">
                            <div className="head">
                                <h2 className="main-h-2">{`MALENA: Machine Learning ESG Analyst`}</h2>
                            </div>
                            <div className="img-div">
                                <div className="each-card img-1">
                                    <div className="title">
                                        <span>{`IFC Historical Data`}</span>
                                    </div>
                                    <div className="icon">
                                        <img alt="ifc historical data" src="../../assets/img/extPre/aboutMalena/his_data.svg" width="28" height="32"></img>
                                    </div>
                                </div>
                                <div className="wrap">
                                    <div className="each-card img-2">
                                        <div className="title">
                                            <span>{`IFC Specialists`}</span>
                                        </div>
                                        <div className="icon">
                                            <img alt="ifc historical data" src="../../assets/img/extPre/aboutMalena/ifc_spe.svg" width="70" height="38"></img>
                                        </div>
                                    </div>
                                    {/* 3 arrows */}
                                    <div className="arr-img-1">
                                        <img alt="arrow1" src="../../assets/img/extPre/aboutMalena/d_arrow.svg" width="20" height="43"></img>
                                    </div>
                                    <div className="arr-img-2">
                                        <img alt="arrow1" src="../../assets/img/extPre/aboutMalena/cur_arrow.svg" width="144" height="43"></img>
                                    </div>
                                    <div className="arr-img-3">
                                        <img alt="arrow1" src="../../assets/img/extPre/aboutMalena/r_arrow.svg" width="45" height="20"></img>
                                    </div>
                                    <div className="each-card img-3">
                                        <div className="title">
                                            <span>{`Create ESG - domain specific AI Models`}</span>
                                        </div>
                                        <div className="icon">
                                            <img alt="ifc historical data" src="../../assets/img/extPre/aboutMalena/dom_spe.svg" width="102" height="24"></img>
                                        </div>
                                    </div>
                                    <div className="each-card img-4">
                                        <div className="title">
                                            <span>{`Create Analytical Capacity for EM Investors`}</span>
                                        </div>
                                        <div className="icon">
                                            <img alt="ifc historical data" src="../../assets/img/extPre/aboutMalena/em_inv.svg" width="122" height="40"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="esg-a">
                            <div className="head">
                                <h2 className="main-h-2">{`ESG Sentiment Analysis`}</h2>
                            </div>
                            <div className="img-div row">
                                <div className="each-info col-md-6 col-lg-6 col-sm-12 pb-4">
                                    <div className="icon">
                                        <img alt="training" src="../../assets/img/extPre/aboutMalena/tra_lab.svg" width="50" height="50"></img>
                                    </div>
                                    <div className="info">
                                        <div className="i-num">{`150,000`}</div>
                                        <div className="i-lab">{`Training Labels`}</div>
                                    </div>
                                </div>
                                <div className="each-info col-md-6 col-lg-6 col-sm-12 pb-4">
                                    <div className="icon">
                                        <img alt="model accuracy" src="../../assets/img/extPre/aboutMalena/mod_acc.svg" width="50" height="50"></img>
                                    </div>
                                    <div className="info">
                                        <div className="i-num">{`91%`}</div>
                                        <div className="i-lab">{`Model Accuracy`}</div>
                                    </div>
                                </div>
                                <div className="each-info col-md-6 col-lg-6 col-sm-12 pt-3 sen-min">
                                    <div className="icon">
                                        <img alt="sentences per minute" src="../../assets/img/extPre/aboutMalena/sen_per.svg" width="50" height="50"></img>
                                    </div>
                                    <div className="info">
                                        <div className="i-num">{`19,000`}</div>
                                        <div className="i-lab">{`Sentences per minute`}</div>
                                    </div>
                                </div>
                                <div className="each-info col-md-6 col-lg-6 col-sm-12 pt-3">
                                    <div className="icon">
                                        <img alt="esg sentiment" src="../../assets/img/extPre/aboutMalena/sen_pre.svg" width="50" height="50"></img>
                                    </div>
                                    <div className="info">
                                        <div className="i-num">{`32,000,000`}</div>
                                        <div className="i-lab">{`ESG sentiments predicted`}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* clickable cards */}
                    <div className="click-cards">
                        <div className="head">
                            <h2 className="main-h-2">{`About MALENA`}</h2>
                        </div>
                        <div className="row cards-row">
                            
                            <div className={`cmn-card col-sm-12 pr-4 col-lg-3`}>
                                <div className="each-click-card" onClick={() => { handleCardClick('MTDS') }}>
                                    <div className="title">
                                        <span><img alt="statistics" src="../../assets/img/extPre/aboutMalena/sum_sta.svg" width="35" height="35"></img></span>
                                        <span className="title-text">{`Model Training Data Summary`}</span>
                                    </div>
                                    <div className="card-para">
                                        <p>{`MALENA has been developed by retraining a large language model using a dataset of over 150,000 manual annotations. With 91% accuracy in ESG sentiment analysis, MALENA outperforms out-of-the-box sentiment analysis models with lower accuracy levels of 68%.`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`cmn-card col-sm-12 pr-4 col-lg-3`}>
                                <div className="each-click-card" onClick={() => { handleCardClick('RAI') }}>
                                    <div className="title">
                                        <span><img alt="responsible ai" src="../../assets/img/extPre/aboutMalena/res_ai.svg" width="35" height="35"></img></span>
                                        <span className="title-text">{`Responsible AI`}</span>
                                    </div>
                                    <div className="card-para">
                                        <p>{`MALENA is underpinned by a Responsible AI framework that incorporates a grounding feature, a model evaluation dashboard, and follows a machine learning operations process to provide transparency, enable users to assess reliability and fairness, and ensure traceability and auditability, promoting trust and transparency in the system.`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`cmn-card col-sm-12 pr-4 col-lg-3`}>
                                <div className="each-click-card" onClick={() => { handleCardClick('MIO') }}>
                                    <div className="title">
                                        <span><img alt="malena input" src="../../assets/img/extPre/aboutMalena/sec_pri.svg" width="35" height="35"></img></span>
                                        <span className="title-text">{`Input and Output`}</span>
                                    </div>
                                    <div className="card-para">
                                        <p>{`MALENA is an “Artificial Intelligence as a Service” solution that analyzes data provided by users. Users upload documents such as sustainability reports, environmental and social impact assessments, and news articles. MALENA identifies over 1,000 ESG risk terms and assigns positive, negative, or neutral sentiment based on context. This information is organized by IFC’s Environmental and Social Performance Standards and Corporate Governance Methodology. Users may filter results by region, country, sector, and sentiment type.`}</p>
                                    </div>
                                </div>
                            </div>
                            
                                <div className={`cmn-card col-lg-3 col-sm-12`}>
                                    <div className="each-click-card" onClick={() => { handleCardClick('MFAQ') }}>
                                        <div className="title">
                                            <span><img alt="malena input" src="../../assets/img/extPre/aboutMalena/sec_faq.svg" width="35" height="35"></img></span>
                                            <span className="title-text">{`Data Security FAQ`}</span>
                                        </div>
                                        <div className="card-para">
                                            <p>{`These Data Security Frequently Asked Questions (FAQs) explain MALENA’s data storage, data access, and data security measures and approach to cybersecurity.`}</p>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                </div>
                // END of about malena home page
            }

            {isLoggedIn ? '' :
                <div className="abt-foot"><PublicFooter></PublicFooter></div>
            }

        </div>
    );
}

export default withRouter(PublicAboutMalena);


