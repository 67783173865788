import React, {useState} from "react";
import './styles/publicDataModal.scss';
import { Modal, ModalBody, Button , ModalHeader} from "reactstrap";


const PublicDataModal = ({ type, showFlag, sendClose }) => {

    const [toggleModal, setToggleModal] = useState(true);

    
    const toggleHandler = () => {
        setToggleModal(!toggleModal);
        sendClose('DO')
    };

    const customClose = <img className="close-icon" onClick={() => { toggleHandler() }} alt="X" src="../../../../assets/img/extPre/ham_cross.svg"></img>;

    return (
        <div className="public-modal-main">
            <Modal toggle={ toggleHandler} backdrop="static" className={`public-modal-wrapper ${type==='DO' ? 'desktop':'default-pop'}`} isOpen={showFlag} centered={true}>
                {/* no header, no footer */}
                {type && type === 'DO' ? // DO = desktop only
                    <>
                    <ModalHeader close={customClose} toggle={toggleHandler}></ModalHeader>
                    <ModalBody className="do-body">
                       <div className="do-box row">
                            <div className="col-12 img-row">
                                <span><img alt="desktop" src="../../../../assets/img/extPre/do_icon.svg" width="40" height="40"></img></span>
                            </div>
                            <div className="col-12 do-text">
                                <p align="center">{`The MALENA dashboard is currently best viewed on the desktop version.`}</p>
                            </div>
                        </div> 
                    </ModalBody>
                    </>
                    :
                    <ModalBody className={`success-wrapper ${type === 'C' ? 'md-body' : ''}`}>
                        {/* <div className='close-btn'><img src={`../../../assets/img/avatars/grey-cross.svg`} alt='close' onClick={() => {sendClose('C')}} className="cross"/></div> */}
                        <div className='success-box'>
                            <img src={`../../../assets/img/Checkmark.svg`} alt='success' className="tick" />
                        </div>
                        <div className='success-msg'>
                            {type === 'R' ?  // registration success
                                <div>
                                    <div className="con-head">{`Thank you for joining the waitlist!`}</div>
                                    <div className="con-text"><p>{`You will receive an email as soon as MALENA is available again.`}</p></div>
                                    <div className=""><Button color="primary" onClick={() => sendClose('C')} name="close popup">{`Close`}</Button></div>
                                </div>
                                : type === 'C' ? //contact us success
                                    <div>
                                        <div className="con-head">{`Thank you for submitting your feedback.`}</div>
                                        {/* <div className="con-text"><p>{``}</p></div> */}
                                        <div className=""><Button color="primary" onClick={() => sendClose()} name="close popup">{`Close`}</Button></div>
                                    </div>

                                    :
                                    <span>{`Thank you for submitting your feedback.`}</span>
                            }
                        </div>
                    </ModalBody>
                }
            </Modal>
        </div>
    )

}

export default PublicDataModal;