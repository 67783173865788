import React from "react";

import { withRouter } from "react-router-dom";

import TermsAndConditions from "../../containers/TermsOfUse/TermsAndConditions";
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import '../../containers/TermsOfUse/termsOfUse.scss';

const PublicTermsAndConditions = ({loginClick}) => {
    return (
        <div className="ptc-main" style={{overflowX: "hidden"}}>
            <PublicHeader type="I" applyWhite={true} loginC={loginClick} ></PublicHeader>
                <div className="terms-wrap"><TermsAndConditions subType="P"></TermsAndConditions></div>
            <PublicFooter></PublicFooter>
        </div>
    )
}

export default withRouter(PublicTermsAndConditions);