import React from "react";

import { withRouter } from "react-router-dom";

import CopyrightStatement from "../../containers/TermsOfUse/CopyrightStatement";
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import "../../containers/TermsOfUse/termsOfUse.scss";

const PublicCopyrightStatement = ({loginClick}) => {
    return (
        <div className="pcs-main" style={{backgroundColor: "#fff", position: "relative", height: "100%", minHeight:"100vh", overflowX: "hidden"}}>
            <PublicHeader type="I" applyWhite={true} loginC={loginClick}  ></PublicHeader>
                <div style={{marginTop: "80px", marginBottom: "150px"}}><CopyrightStatement subType="P"></CopyrightStatement></div>
            <div className="f-base"><PublicFooter></PublicFooter></div>
        </div>
    )
}

export default withRouter(PublicCopyrightStatement);