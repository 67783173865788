import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import AuthenticationWrapper from './authenticationWrapper';
import App from './App';
import store from './authStore';
import * as serviceWorker from './serviceWorker';
import './fonts/Roboto-Italic.ttf';
import './fonts/Roboto-Medium.ttf';
import './fonts/Roboto-MediumItalic.ttf';
import './fonts/Roboto-Regular.ttf';

ReactDOM.render(
    <AuthenticationWrapper app={props => (
            <Provider store={store}>
                <App {...props} />
            </Provider>
        )}
    >
    </AuthenticationWrapper>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
