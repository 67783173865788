import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";
import * as excelJs from 'exceljs';
import { apiBasePath, handleErrors, handleErrorsForText, handleErrorsTextWithClone, getAuthHeader, getAuthHeaderPBI, getAuthHeaderForText, handleErrorsObject, getAuthHeaderBlob } from "../../common/APIUtils";
import { getErrorMessages, supportedTotalsizePerUpload, formatBytes, supportedUploadDocCount } from "../../common/Utils";

export const getProjectDetails = (id) => {
    return fetch(`${apiBasePath}/api/SelfService/getproject/${id ? id : ''}`, getAuthHeader()).then(handleErrorsObject);
}

export const getRecentActivity = () => {
    return fetch(`${apiBasePath}/api/SelfService/recent`, getAuthHeader()).then(handleErrors);
}

export const getAllProjects = () => {
    return fetch(`${apiBasePath}/api/SelfService/`, getAuthHeader()).then(handleErrorsObject);    
}

export const getExtConfigApi = () => {
    return fetch(`${apiBasePath}/api/SelfService/getConfig`, getAuthHeader()).then(handleErrorsObject);
}

export const createProject = (payload) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        getProjectData: () => fetch(`${apiBasePath}/api/SelfService/createproject`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrors)
    };
}

export const postDocMetaData = (payload) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        postMetaData: () => fetch(`${apiBasePath}/api/SelfService/documents`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrors)
    };
}

export const uploadFiles = (fileData, batchIndex) => {
    const token = sessionStorage.getItem('access1');
    return axios.post(`${apiBasePath}/api/SelfService/uploadsv2`, fileData,
    {headers: {Authorization:`Bearer ${token}`,"Content-Type": "multipart/form-data", "x-batchIndex": batchIndex }});
}

export const handleUploadErrors = (apiName, fullOutput, batchArray, fromPage) => {
    // let msg = getErrorMessages('500');
    let msg = '';
    let fDocsArray = [];
    let isPartialFail = false;
    if (apiName === 'U') { 
        let onlyErr = [];
        fullOutput.map(ele => {
            if (ele.value && ele.value.status === 200) {
                isPartialFail = true;
            } else {
                onlyErr.push(ele);
            }
        });
        if (onlyErr && onlyErr.length > 0) {
            
            onlyErr.forEach(e => {
                if (e.value.config && e.value.config.headers) {
                    let ind = e.value.config.headers['x-batchIndex'];
                    if (ind || ind === 0) {
                        let matchedArr = batchArray[ind] ? batchArray[ind] : [];
                        fDocsArray = [...fDocsArray, ...matchedArr];                        
                    }                    
                }
            });

            if (fDocsArray.length > 0) {
                if (!isPartialFail) { // all failed docs
                    fDocsArray = []; // for all failes, no need to show all
                    msg = getErrorMessages('U','V_1');  // from quick upload and normal upload
                } else { // partially failed docs
                    msg = getErrorMessages('U', 'V_2');
                }
            }
        }

        // upload api call does not have any docs to upload but failed
        if (batchArray && batchArray[0] && batchArray[0].length === 0 
            && fullOutput[0] && fullOutput[0].value && fullOutput[0].value.status !== 200) {
            msg = getErrorMessages('500');
        }
    }
    return {
        'msg': msg,
        "failedDocList": fDocsArray
    }
}

export const deleteProjectApi = (projectId, name) => {
    return fetch(`${apiBasePath}/api/SelfService/project/${projectId}`, {
        method: 'POST',
        body: JSON.stringify({'project_id': projectId,'project_name': name}),        
        headers: {
            'Content-type': 'application/json',
            ...getAuthHeader()['headers']
        }
    }).then(handleErrorsTextWithClone);
}


export const setProjectLock = (payload) => {
    return fetch(`${apiBasePath}/api/SelfService/setlock`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json',
            ...getAuthHeader()['headers']
        }
    }).then(handleErrors);
}

export const releaseProjectLock = (payload) => {
    return fetch(`${apiBasePath}/api/SelfService/releaselock`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json',
            ...getAuthHeader()['headers']
        }
    }).then(handleErrors);
}

export const validateAuthorization = (projectId) => {
    return fetch(`${apiBasePath}/api/SelfService/validate/${projectId}`, getAuthHeader()).then(handleErrors);    
}
export const getSelfExportDocument = (skip, top, query, order, iscg)=>{
    const controller = new AbortController();
    const baseUrl = `${apiBasePath}/api/SelfService/export`
    const queryObj= {
        'orderby': order,
        'skip': skip,
        'top': top,
        'iscg': iscg,
        ...query
    };
    return{
        abort: () => controller.abort(),
        getData: () => axios.post(baseUrl,queryObj, {
            headers: {
                ...getAuthHeaderBlob()['headers']
            },
            responseType: 'arraybuffer'                    
        })
    }
}

export const getSelfDocuments = (skip, top, query, order,iscg) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = {
        'orderby': order,
        'skip': skip,
        'top': top,
        'iscg': iscg,
        ...query
    };
    return {
        abort: () => controller.abort(),
        getSelfDocsData: () => fetch(`${apiBasePath}/api/SelfService/getprojectdocs`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrors)
    };
}

export const getSelfDocWidgetDetails = (docId) => {
    
    const controller = new AbortController();
    const { signal } = controller;
    
    return {
        abort: () => controller.abort(),
        //getData: () => fetch(`${apiBasePath}/odata/documentinsight?documentid='${documentId}'&$skip=${skip}&$top=${top}&$count=true`, {
        getSelfDocData: () => fetch(`${apiBasePath}/api/SelfService/getdocument/${docId ? docId : ''}?count=true`, {
        signal,
            ...getAuthHeader()
        }).then(handleErrors)
    };
}

export const projectCreationPayload = (type,payload, isEdit = false, oldCmpList) => {
    let companyArr = [];
    let projObj = {
        "name": payload.name,
    } 
    if (type === 'cmp') {
        projObj['iscmp'] = true;
        let eachCompObj = {
            "company": payload.company,
            "description": payload.description,
            "sector": payload.sector,
            "region": payload.region,
            "country": payload.country,
        };
        if (payload.old_company) {
            eachCompObj['old_company'] = payload.old_company;
            eachCompObj['old_country'] = payload.old_country;
            eachCompObj['old_region'] = payload.old_region;
            eachCompObj['old_sector'] = payload.old_sector;
            eachCompObj['action'] = 'edit';
        }
        companyArr.push(eachCompObj);

    } else if (type === 'portfolio') {
        projObj['iscmp'] = false;
        payload.companyList.forEach((cp) => {
            let eachCompObj = {
                "company": cp.name,
                "sector": cp.sector ? cp.sector.value : '',
                "region": cp.region,
                "country": cp.country ? cp.country.value: '',
            };
            if(cp.old_company) {
                eachCompObj['old_company'] = cp.old_company;
                eachCompObj['old_country'] = cp.old_country;
                eachCompObj['old_region'] = cp.old_region;
                eachCompObj['old_sector'] = cp.old_sector;                
            }
            if(cp.action) {
                eachCompObj['action'] = cp.action; 
            }
            companyArr.push(eachCompObj);
        });

        if(oldCmpList && oldCmpList.length > 0) {
            oldCmpList.forEach(item => {
                if (item.action) {
                    let eachCObj = {
                        "company": item.name,
                        "sector": item.sector ? item.sector.value : '',
                        "region": item.region,
                        "country": item.country ? item.country.value: '',
                    };
                    if(item.old_company) {
                        eachCObj['old_company'] = item.old_company;                
                    }
                    if(item.action) {
                        eachCObj['action'] = item.action; 
                    }
                    companyArr.push(eachCObj);
                }
               
            });
        }
        
    }

    projObj['company'] = companyArr;
    
    
    if (isEdit || payload.id) {
        projObj['project_id'] = payload.id;
    } 
    if(payload.old_project_name) {
        projObj['old_project_name'] = payload.old_project_name;
    }
    return projObj;
};

export const fileUploadPayload = (type, allFiles = [], projectPayload, id) => {
    const formData = new FormData();
    formData.append('projectId', id);

    const newFiles = allFiles.filter(ele => {
        return ele && ele.path;
    });

    newFiles.forEach((file,ind) => {
        const fName = file.name;
        let cName = projectPayload.company;
        if (type === 'portfolio') {
            cName = file.docCompanyName ? file.docCompanyName.value: '';
        }        
        const newFileName = cName + ';' + fName;
        formData.append(`formFile${ind}`, file);
        formData.append(`fileName${ind}`, newFileName);
    });

    return formData;
};

export const metaDataDocsPayload = (type, finalCreationPayload, allFiles=[], uploadOutput=[], projectId, deletedFiles) => {
    let bodyObj = {
        'project_id': projectId,
        'project_name': finalCreationPayload.name
    }
    let failedFileNames = [];
    if (uploadOutput.length > 0) {        
        uploadOutput.forEach(el => {            
            const arr = el.status.split(':');
            if (arr[1].includes('Success')) {
            } else {
                failedFileNames.push(el.document_nme.trim());
            }
            allFiles.forEach(file => {
                if (file.name === el.document_nme) {
                    file['docId'] = el.document_id;
                    file['lan'] = el.language;
                    file['pages'] = el.pages;
                    file['trans'] = el.translation;
                }
            });
        });
    }
    let docsArr = [];
          
    allFiles.forEach(file => {
        let docObj = {
            "document_id": file.docId? file.docId: '',            
            "document_name": file.name,
            "document_size": file.size,
            "document_date": file.docDate ? moment(file.docDate).format('YYYY-MM-DD') : '',
            "document_type": file.docType ? file.docType.value : '',
            "reliability": file.docReliability ? file.docReliability.value : '',
        };
        
        if(file.action) {
            docObj['action'] = file.action;
        }
        if(file.action && file.action === 'edit'){
            docObj['sendMail'] = 'no';
        }else {
            docObj['sendMail'] = 'yes';
        }
        if (file.lan) {
            docObj['language'] = file.lan;
            docObj['pages'] = file.pages;
            docObj['translation'] = file.trans;
        }
        
        docObj['data_classification'] = 'Confidential';
        docObj['is_disclaimer'] = true;
        docObj['created_date'] = moment(new Date()).format();

        if (type === 'portfolio') {
            docObj["company"] = file.docCompanyName ? file.docCompanyName.value: '';            
        } else if (type === 'cmp') {           
            docObj["company"] = finalCreationPayload.company[0].company;
        }
        if ((failedFileNames.length > 0 && failedFileNames.includes(file.name)) || docObj.document_id === '') {

        } else {
            docsArr.push(docObj);
        }
    });
    
    if (deletedFiles && deletedFiles.length > 0) {
        deletedFiles.forEach(file => {
            let docObj = {
                "document_id": file.docId? file.docId: '',
                "document_name": file.name,
                "document_size": file.size,
                "document_date": file.docDate ? moment(file.docDate).format() : '',
                "document_type": file.docType ? file.docType.value : '',
                "reliability": file.docReliability ? file.docReliability.value : '',
                "action": 'delete',
                'sendMail': 'yes'
            };
            if (type === 'portfolio') {
                docObj["company"] = file.docCompanyName ? file.docCompanyName.value: '';            
            } else if (type === 'cmp') {
                docObj["company"] = finalCreationPayload.company[0].company;
            }
            docsArr.push(docObj);
        });
    }
    
    
    bodyObj["docs"] = docsArr;

    return bodyObj;  

};

export const fetchEmails = (query) => {
    const controller = new AbortController();
    const { signal } = controller;
    
    return {
        abort: () => controller.abort(),
        //getData: () => fetch(`${apiBasePath}/odata/documentinsight?documentid='${documentId}'&$skip=${skip}&$top=${top}&$count=true`, {
        getSearchEmails: () => fetch(`${apiBasePath}/api/SelfService/findusers?term=${query}`, {
        signal,
            ...getAuthHeader()
        }).then(handleErrors)
    };
}

export const fetchLandingCount = () => {
    const controller = new AbortController();
    const { signal } = controller;
    
    return {
        abort: () => controller.abort(),
        //getData: () => fetch(`${apiBasePath}/odata/documentinsight?documentid='${documentId}'&$skip=${skip}&$top=${top}&$count=true`, {
        getSummaryCount: () => fetch(`${apiBasePath}/api/SelfService/getcount`, {
        signal,
            ...getAuthHeader()
        }).then(handleErrors)
    };
}
export const fetchRegLandingCount = () => {
    const controller = new AbortController();
    const { signal } = controller;
    
    return {
        abort: () => controller.abort(),
        //getData: () => fetch(`${apiBasePath}/odata/documentinsight?documentid='${documentId}'&$skip=${skip}&$top=${top}&$count=true`, {
        getSummaryCount: () => fetch(`${apiBasePath}/api/SelfService/getUserLimit`, {
        signal,
            ...getAuthHeader()
        }).then(handleErrors)
    };
}
export const fetchShareEmails = (id) => {
    const controller = new AbortController();
    const { signal } = controller;
    
    return {
        abort: () => controller.abort(),
        //getData: () => fetch(`${apiBasePath}/odata/documentinsight?documentid='${documentId}'&$skip=${skip}&$top=${top}&$count=true`, {
        getShareEmails: () => fetch(`${apiBasePath}/api/SelfService/share/${id}`, {
        signal,
            ...getAuthHeader()
        }).then(handleErrors)
    };
}

export const postShareEmails = (payload) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        postShareData: () => fetch(`${apiBasePath}/api/SelfService/share`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrors)
    };
}

export const postContactUs = (payload) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        postContactUsData: () => fetch(`${apiBasePath}/api/SelfService/contactus`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrors)
    };
  }

export const getNoRiskTermDocsApi = (payload) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        getNoRiskTermDocs: () => fetch(`${apiBasePath}/api/SelfService/getNoRiskTermDocs`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrors)
    };
}

export const postFailedDocApi = (payload) => { // returns string 'success' as response
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        reportFailedDoc: () => fetch(`${apiBasePath}/api/SelfService/reportFailedDoc`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                ...getAuthHeader()['headers']
            }
        }).then(handleErrorsForText)
    };
}

export const validateOverAllSize = (allF) => {
    let flagObj = {'valid': true,'size': false, 'count': false};

    let dup = [...allF];
    let onlyNewFiles = [];
    onlyNewFiles = dup.filter(ele => {
        return ele && ele.path;
    });
    let totalFileCount = onlyNewFiles.length;
    let totalSize = onlyNewFiles.reduce((p,c) => p + c.size, 0);
    let sizeMax = supportedTotalsizePerUpload();
    let numberMax = supportedUploadDocCount();
   
    let limitSize = sizeMax * 1048576; // 1048576 bytes = 1 MB

    if (totalFileCount > (numberMax)) {
        flagObj.valid = false;
        flagObj.count = true;
    }
    if (totalSize > limitSize) {
        flagObj.valid = false;
        flagObj.size = true;
    }
    return flagObj;
}

export const getAccessType = (projectObj) => {
    let type = 'O';
    if (projectObj.isOwner) {
        type = 'O';
    } else if (projectObj.isEdit) {
        type = 'E';
    } else if (!projectObj.isEdit) {
        type = 'V';
    }
    return type;
}

export const scanUploadDoc = (payload, name, size) => {
    const token = sessionStorage.getItem('access1');
    return axios.post(`${apiBasePath}/api/SelfService/scan`, payload,
    {headers: {Authorization:`Bearer ${token}`,"Content-Type": "multipart/form-data" , "x-fileName": name, "x-fileSize": size}});
}

export const triggerPowerBiRefresh = (payload) => {
    const controller = new AbortController();
    const { signal } = controller;
    const body = payload;
    
    return {
        abort: () => controller.abort(),
        postPbiRefresh: () => fetch(`https://api.powerbi.com/v1.0/myorg/groups/d980bb31-2ff4-4760-8274-0c429174bcc2/datasets/3e19aedc-9ec0-4a95-abcb-30ab8d18af11/refreshes`, {
            method: 'POST',
            body: JSON.stringify(body),
            signal,
            headers: {
                'Content-type': 'application/json',
                'Resource': 'https://analysis.windows.net/powerbi/api',
                ...getAuthHeaderPBI()['headers']
            }
        }).then(handleErrors)
    };
}

// bulk upload methods

// new version with excel.js
export const handleExport = async(exportData, docTypeEx, cmpListEx, projectName, docCount, type, isShared=false)=>{
    const workbook = new excelJs.Workbook();

    // worksheet 1 configurations
    const worksheet = workbook.addWorksheet('uploaded_data',{views: [{state: 'frozen', ySplit:1}]});
    let cols = [
        { header: 'DocumentName', key: 'docName', width: 26 },
        { header: 'Size', key: 'size', width: 10 },
        { header: 'Date', key: 'date', width: 14 },
        { header: 'DocType', key: 'docType', width: 22 }      
    ];
    if (isShared) {
        cols.splice(2, 0, {header: 'Shared By', key: 'sharedby', width: 22})
    }
    if(type === 'portfolio'){
        cols.push({ header: 'CompanyName', key: 'compName', width: 18 });
    }

    worksheet.columns = cols;
    worksheet.addRows(exportData);
    worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFFFF' } };
    worksheet.getRow(1).fill = { type: 'pattern', pattern:'solid', fgColor: { argb:'FF2684FF' } };
    worksheet.protect();

    for(let i=2; i<=docCount+1; i++){
        worksheet.getCell('A'+i).font = { color: { argb: 'FF999999' } };
        worksheet.getCell('B'+i).font = { color: { argb: 'FF999999' } };
        if (isShared) {
            worksheet.getCell('C'+i).font = { color: { argb: 'FF999999' } };
            worksheet.getCell('D'+i).protection = {locked: false};
            worksheet.getCell('E'+i).protection = {locked: false};
        } else {            
            worksheet.getCell('C'+i).protection = {locked: false};
            worksheet.getCell('D'+i).protection = {locked: false};
        }
        
        if(type === 'portfolio'){
            if(isShared) {
                worksheet.getCell('F'+i).protection = {locked: false};
            } else {
                worksheet.getCell('E'+i).protection = {locked: false};
            }            
        }  
    }
    
    // worksheet 2 configurations
    const worksheet2 = workbook.addWorksheet('dropDown_opts',{views: [{state: 'frozen', ySplit:1}]});    
    worksheet2.getColumn(1).width = 30;
    worksheet2.getColumn(1).values = docTypeEx;
    if(type === 'portfolio'){
        worksheet2.getColumn(3).width = 18;
        worksheet2.getColumn(3).values = cmpListEx;
    }
    worksheet2.getRow(1).font = { bold: true, color: { argb: 'FFFFFFFF' } };
    worksheet2.getRow(1).fill = { type: 'pattern', pattern:'solid', fgColor: { argb:'FF2684FF' } };
    worksheet2.protect();

    // data validation on sheet 1
    const docTypeNo = docTypeEx.length;
    worksheet.dataValidations.add(`${isShared ?'E2:E': 'D2:D'}`+(docCount+1), {
        type: 'list',
        allowBlank: false,
        // formulae: [`${docTypeEx}`],
        formulae:  ['=dropDown_opts!$A$2:$A$'+docTypeNo]
    });
    if(type === 'portfolio'){
        const cmpNo = cmpListEx.length;
        worksheet.dataValidations.add(`${isShared ?'F2:F': 'E2:E'}`+(docCount+1), {
            type: 'list',
            allowBlank: false,
            // formulae: [`${cmpListEx}`],
            formulae:  ['=dropDown_opts!$C$2:$C$'+cmpNo]
        });
    }

    // export as xlsx file
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    );
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = `${projectName}_metaData.xlsx`;
    link.click();
}

// // old version with xlsx
// export const handleExport = (exportData, docTypeEx, cmpListEx, projectName, type) =>{
//     let wb = XLSX.utils.book_new();
//     let ws1 = XLSX.utils.aoa_to_sheet(exportData);
//     XLSX.utils.book_append_sheet(wb, ws1, `uploaded_data`);
//     let ws2 = XLSX.utils.aoa_to_sheet(docTypeEx);
//     XLSX.utils.book_append_sheet(wb, ws2, `doc_type`);
//     if(type === 'portfolio'){
//         let ws3 = XLSX.utils.aoa_to_sheet(cmpListEx);
//         XLSX.utils.book_append_sheet(wb, ws3, `comp_name`);
//     }
//     XLSX.writeFile(wb, projectName+`_metaData.xlsx`);
// }

export const getExcelData = (file)=>{
    const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);   
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);                  
          resolve(data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      return promise;
}

export const importValidation =(importedData, docTypeValues, compValues, onlyValidFiles, type) =>{
    let excelErrMsg = "There is a mismatch entry in the metadata. Excel details are not matching with the metadata details.";        
    let oldValidFileData = [...onlyValidFiles];  
    let excelErrorArr = [];   
    let formatFlag = false;  
    importedData?.map(impData =>{
        let proprtyName = Object.keys(impData);            
        if(proprtyName.some(key => key.includes('__EMPTY')) || !impData.hasOwnProperty('DocumentName') || !impData.hasOwnProperty('Size')){
            formatFlag = true;
        }else{
            for(let i=0;i<oldValidFileData.length;i++){
                if(impData.DocumentName === oldValidFileData[i].name && impData.Size === formatBytes(oldValidFileData[i].size)){
                    let today = new Date();
                    let currObj = oldValidFileData[i];
                    currObj['dateErr'] = false;
                    currObj['docTypeErr'] = false;
                    if(type === 'portFolio'){
                        currObj['compErr'] = false;
                    }
                    let excelDate, docTypeObj, compObj;
                    // Date validation
                    if(impData.Date){
                        let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
                        if(typeof(impData.Date) === 'string' &&  date_regex.test(impData.Date)){
                            excelDate = moment(impData.Date, 'MM/DD/YYYY').toDate();
                        }else if(typeof(impData.Date) === 'number'){
                            let parseDate = parseDateExcel(impData.Date);
                            if(parseDate !== null){
                                excelDate = new Date(parseDate);
                            }else{
                                excelDate = moment('01/01/1970', 'MM/DD/YYYY').toDate();
                                currObj['dateErr'] = true;
                                excelErrorArr.push(excelErrMsg);
                            }
                        }else{
                            excelDate = moment('01/01/1970', 'MM/DD/YYYY').toDate();
                            currObj['dateErr'] = true;
                            excelErrorArr.push(excelErrMsg);
                        }                                               
                    }else{
                        excelDate = today;
                    }
                    if(excelDate > today){
                        currObj['dateErr'] = true;
                        excelErrorArr.push(excelErrMsg);
                    }
                    // Doc type validation
                    if(impData.DocType){
                        if(typeof(impData.DocType) ==='string' ){
                            docTypeObj = {label:impData.DocType, value:impData.DocType};
                            if(!docTypeValues.includes(impData.DocType) && impData.DocType !== 'Not Assigned'){
                                currObj['docTypeErr'] = true;
                                excelErrorArr.push(excelErrMsg);
                            }
                        }else{
                            if(impData.DocType !== null && impData.DocType !== undefined){
                                docTypeObj = {label: impData.DocType+'', value: impData.DocType+''};
                            }
                            currObj['docTypeErr'] = true;
                            excelErrorArr.push(excelErrMsg);
                        }
                    }else{
                        docTypeObj = {label:"Not Assigned", value:"Not Assigned"};
                    }
                    // Company name validation
                    if(type === 'portfolio'){
                        if(impData.CompanyName){
                            if(typeof(impData.CompanyName) === 'string'){
                                compObj = {label:impData.CompanyName, value:impData.CompanyName};
                                if(!compValues.includes(impData.CompanyName)){
                                    currObj['compErr'] = true;
                                    excelErrorArr.push(excelErrMsg);
                                }
                            }else{
                                if(impData.CompanyName !== null && impData.CompanyName !== undefined){
                                    compObj = {label: impData.CompanyName+'', value: impData.CompanyName+''};
                                }
                                currObj['compErr'] = true;
                                excelErrorArr.push(excelErrMsg);
                            }                            
                        }
                    }
                    currObj.docDate = excelDate;
                    currObj.docType = docTypeObj;
                    if(type === 'portfolio'){
                        currObj.docCompanyName = compObj;
                    }
                }
            }
        }            
    })
    return {
        'formatFlag':formatFlag,
        'excelError':excelErrorArr,
        'validFiles':oldValidFileData
    }
}
const parseDateExcel = (excelTimestamp) => {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
};

// export const isBetaUser = () => { return localStorage && localStorage.getItem('isBeta') === 'true' ? true: false};

// export const isBetaUser = () => { return  true};

export const extractUserDocLimits = () => {
    if (sessionStorage && sessionStorage.getItem('userLimits')) {
        return JSON.parse(sessionStorage.getItem('userLimits'));
    } else {
        return null
    }
}

export const storeUserDocLimits = (val) => {
    sessionStorage.setItem('userLimits', val ? JSON.stringify(val) : null);
}

export const getUserGuidePdfApi = () => {
    
    return {
        userGuidePdfDownload: () => axios.get(`${apiBasePath}/malena/getUserGuide`, {
            // body: JSON.stringify(body),
            headers: {
                ...getAuthHeaderBlob()['headers']
            },
            responseType: 'arraybuffer'                    
        })
    };
}