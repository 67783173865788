// useScreenSize.js
import { useState, useEffect, useCallback } from 'react';

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const debounceFun = (func) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, 500);
    };
};

const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {    

    const optimizedHandleResize = debounceFun(handleResize);

    window.addEventListener('resize', optimizedHandleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', optimizedHandleResize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;