import React,  { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Spinner.scss';
class Spinner extends Component {
    state={
        showMsg: false
    }
    
    
//const Spinner = props => (
    render(){

        return(
    <>
     {window.location.href.includes('/myAssessments') ?
    <div className="animated fadeIn  justify-content-center align-items-center h-100 upload_loader">
        {this.props.showLoader ? <div className="process_msg">
            <p className="mb-0 process_txt">Processing...</p>
            <p className="mb-0 process_subtxt"> Please  wait while MALENA is analyzing your documents.</p>
            <p className="mb-0 process_subtxt">This can take a few minutes.</p>
        </div>: ""}
        <div className={`upload_spinner spinner-border spinner-color ${this.props.classNames || ''}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div> :
    <div className="animated fadeIn d-flex justify-content-center align-items-center h-100">
    <div className={`spinner-border spinner-color ${this.props.classNames || ''}`} role="status">
        <span className="sr-only">Loading...</span>
    </div>
</div>}
     </>
        )
    }
}
//);
export default Spinner;
//export default Spinner;
