import React, {useState, useEffect} from "react";
import {Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import { useHistory } from "react-router";
import PublicFooter from "./PublicFooter";

import './publicHeader.scss';

const PublicHeader = ({activePageName, applyWhite, pType, loginC, sendToPar}) => {

    const [isHamOpen, setIsHamOpen] = useState(false);
    const [isHamTabOpen, setIsHamTabOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (isHamOpen) {            
            document.querySelector('body').classList.add('mobile-nav-active');
        } else {
            document.querySelector('body').classList.remove('mobile-nav-active');
        }
        
    }, [isHamOpen]);

    
    const navigateTo = (type, sub_type) => {
        if (sub_type === 'HAM') {
            setIsHamOpen(false);
        }
        if (pType !== 'W') {
            if (type === 'H') {
                history.push('/');
            } else if (type === 'A') {
                history.push('/public/about-malena'); 
            } else if (type === 'S') {
                history.push('/public/registration');                      
            } else if (type === 'L') {
                history.push('/public/login-pre');  
            }
        } else {
            if (type === 'LO') {
                sendToPar('LO');
            }
        }
        
    }

    const onHamClick = (val) => {
        setIsHamOpen(val);        
    }

    const onHamTabClick = (val) => {
        setIsHamTabOpen(val);
    }

    return (
        <div className={`pub-header-main ${applyWhite ? 'sd': ''} ${isHamOpen ? 'ham-mob-active' : isHamTabOpen ? 'ham-tab-active': ''}`}>
            
            <div className="ham-mob-menu-main">
                <div className="ham-cro" style={{ background: `url('../../../assets/img/extPre/ham_bg.svg') no-repeat 100% bottom, url('../../../assets/img/extPre/ham_bg_2.svg') no-repeat 100% bottom` }}>
                    <div className="t">
                        <span className="mal-l-img">
                            <img alt="Malena logo" width="108" height="31" src="../../assets/img/extPre/malena-white.svg" onClick={() => navigateTo('H','HAM')} ></img>
                        </span>
                            <span className="ifc-l-img">
                                <a target="_blank" href="https://www.ifc.org/en/home"><img width="120" height="27" src="../../assets/img/extPre/ifc-white.svg" alt="ifc logo"></img></a>
                        </span>
                    </div>
                    <div className="i">
                        {/* // add cross icon img */}
                        <img onClick={() => onHamClick(!isHamOpen)} alt="close" src="../../assets/img/extPre/ham_cross.svg" width="28" height="28"></img>
                    </div>
                </div>
                <ul className="ham-ul">
                    {/* <li><span className={`link ${activePageName==='H'? 'active':''}`} onClick={() => navigateTo('H','HAM')}>{`Home`}</span></li> */}
                    <li><span className={`link ${activePageName==='A'? 'active':''}`} onClick={() => {navigateTo('A','HAM')}}>{`About MALENA`}</span></li>
                    <li><Button color="primary" 
                        onClick={() => navigateTo('L', 'HAM')} 
                        // onClick={() => loginC()}
                        name="login" className="li-btn">Log In</Button></li>
                    <li><Button color="primary" onClick={() => navigateTo('S','HAM')} name="signup" className="su-btn">Sign Up</Button></li>
                </ul>
                <div className="f-row">
                    <PublicFooter noPartners={false}></PublicFooter>
                </div>
            </div> 
                        
            <div className="row desktop-header-main">
            <div className="col-12 d-flex align-items-center">
                <span className={`logo ${applyWhite ? 'bg-w': 'bg-t'}`}>
                    <span className="mal-l-img">
                    { applyWhite ?
                    <img alt="Malena logo" width="197" height="56.74" src="../../assets/img/extPre/malena-blue.svg" onClick={() => navigateTo('H')} ></img>
                    : 
                    <img alt="Malena logo" width="197" height="47" src="../../assets/img/extPre/malena-white.svg" onClick={() => navigateTo('H')} ></img>
                    }
                    </span>
                    
                    <span className="ifc-l-img">
                        <a target="_blank" href="https://www.ifc.org/en/home">
                            <img width="244" height="54" src={applyWhite ? "../../assets/img/extPre/ifc-blue.svg":"../../assets/img/extPre/ifc-white.svg"} alt="ifc logo"></img></a></span>
                </span>
                <span className="link-click-binder">
                    {pType === 'W' ?
                    <>                        
                    </>:
                    <>
                    <span className="n-links">
                        {/* <span className={`link ${activePageName==='H'? 'active':''}`} onClick={() => navigateTo('H')}>{`Home`}</span> */}
                        <span className={`link ${activePageName==='A'? 'active':''}`} onClick={() => {navigateTo('A')}}>{`About MALENA`}</span>
                    </span>
                    <span className="b-clicks">
                        <Button color="primary" 
                        onClick={() => navigateTo('L')} 
                        // onClick={() => loginC()}
                        name="login" className="log-in-btn">Log In</Button>
                        <Button color="primary" onClick={() => navigateTo('S')} name="signup" className="sign-up-btn">Sign Up</Button>
                    </span>
                    <span className="pl-4 ham-menu-tab"><img width="25" height="25" onClick={() => onHamClick(!isHamOpen)} src={applyWhite ? "../../assets/img/extPre/ham-blue.svg":"../../assets/img/extPre/ham-grey.svg"} alt="hamburger-menu"/></span>
                    <Dropdown className="ham-tab-dd" isOpen={isHamTabOpen} toggle={() => {onHamTabClick(!isHamTabOpen)}}>
                        <DropdownToggle data-toggle="dropdown" tag={'div'}>
                            <span className="pl-4 ham-menu-tab-only"><img width="25" height="25" onClick={() => onHamTabClick(!isHamTabOpen)} src={applyWhite ? "../../assets/img/extPre/ham-blue.svg":"../../assets/img/extPre/ham-grey.svg"} alt="hamburger-menu"/></span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {/* <DropdownItem tag="div"><span className={`link ${activePageName==='H'? 'active':''}`} onClick={() => navigateTo('H')}>{`Home`}</span></DropdownItem> */}
                            <DropdownItem tag="div"><span className={`link ${activePageName==='A'? 'active':''}`} onClick={() => {navigateTo('A')}}>{`About MALENA`}</span></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    </>
                    }                        
                </span>                                        
            </div>
        </div>           
            
        </div>
    );
}

export default PublicHeader;